// export default {
//   items: [
//     {
//       id: "navigation",
//       title: "Navigation",
//       type: "group",
//       icon: "icon-navigation",
//       children: [
//         {
//           id: "dashboard",
//           title: "Overview",
//           type: "item",
//           url: "/home/dashboard",
//           icon: "feather icon-home",
//         },
//       ],
//     },
//     {
//       id: "clients",
//       title: "Clients",
//       type: "item",
//       url: "/home/clients",
//       classes: "nav-item",
//       icon: "fa fa-user-circle",
//       permission:"CanViewClient"
//     },
//     {
//       id: "client-gateway-microservice",
//       title: "Client Gateway Microservice",
//       type: "item",
//       url: "/home/client-gateway-microservice",
//       classes: "nav-item",
//       icon: "fa fa-microchip",
//     },
//     {
//       id: "payment",
//       title: "Payment",
//       type: "group",
//       icon: "icon-ui",
//       children: [
//         {
//           id: "payment-basic",
//           title: "Payment",
//           type: "collapse",
//           icon: "fa fa-money",
//           children: [
//             {
//               id: "payment-services",
//               title: "Payment Services",
//               type: "item",
//               url: "/home/payment-services",
//             },
//             {
//               id: "payment-services-groups",
//               title: "Payment Services Groups",
//               type: "item",
//               url: "/home/payment-services-groups",
//             },
//           ],
//         },
//       ],
//     },
//     // {
//     //   id: "payment-gateways",
//     //   title: "Payment Gateway",
//     //   type: "item",
//     //   url: "/home/payment-gateway",
//     //   classes: "nav-item",
//     //   icon: "fa fa-building",
//     // },
//     {
//       id: "payment-gateways",
//       title: "Gateway",
//       type: "group",
//       icon: "icon-ui",
//       children: [
//         {
//           id: "payment-gateway",
//           title: "Gateway",
//           type: "collapse",
//           icon: "fa fa-building",
//           children: [
//             {
//               id: "payment-services",
//               title: "Payment Gateway",
//               type: "item",
//               url: "/home/payment-gateway",
//             },
//             {
//               id: "payment-destination-bank",
//               title: "Bank Destination",
//               type: "item",
//               url: "/home/destinationbank",
//             },
//             {
//               id: "payment-gateway-response-code-map",
//               title: "Gateway Response Code Map",
//               type: "item",
//               url: "/home/gatewayresponse-code-map",
//             },
//             {
//               id: "payment-response-code",
//               title: "Response Code",
//               type: "item",
//               url: "/home/responsecode",
//             }
//           ],
//         },
//       ],
//     },
//     {
//       id: "microservice",
//       title: "Microservices",
//       type: "item",
//       url: "/home/microservices",
//       classes: "nav-item",
//       icon: "fa fa-microchip",
//     },
//     {
//       id: "country",
//       title: "Country",
//       type: "item",
//       url: "/home/country",
//       classes: "nav-item",
//       icon: "fa fa-globe",
//     },

//     {
//       id: "currency",
//       title: "Currency",
//       type: "item",
//       url: "/home/currency",
//       classes: "nav-item",
//       icon: "fa fa-dollar",
//     },
//     {
//       id: "payment-currency-pair",
//       title: "Currency Pair",
//       type: "item",
//       url: "/home/currencypair",
//       classes: "nav-item",
//       icon:"fa fa-money"
//     },
//     {
//       id: "ui-usermanagement",
//       title: "UI ELEMENT",
//       type: "group",
//       icon: "icon-ui",
//       children: [
//         {
//           id: "user-basic",
//           title: "User Management",
//           type: "collapse",
//           icon: "fa fa-user",
//           children: [
//             {
//               id: "user",
//               title: "Users",
//               type: "item",
//               url: "/home/users",
//             },
//             {
//               id: "roles",
//               title: "Roles",
//               type: "item",
//               url: "/home/roles",
//             },
//           ],
//         },
//       ],
//     },
//   ],
// };

export default {
  items: [
    {
      id: "navigation",
      title: "Navigation",
      type: "group",
      icon: "icon-navigation",
      children: [
        {
          id: "dashboard",
          title: "Overview",
          type: "item",
          url: "/home/dashboard",
          icon: "feather icon-home",
          permission: ["CanViewDashboard"],
          code: "CanViewDashboard",
        },
      ],
    },
    {
      id: "clients",
      title: "Clients",
      type: "item",
      url: "/home/clients",
      classes: "nav-item",
      icon: "fa fa-user-circle",
      permission: ["CanViewClient"],
      code: "CanViewClient",
    },
    {
      id: "client-gateway-microservice",
      title: "Client Gateway Microservice",
      type: "item",
      url: "/home/client-gateway-microservice",
      classes: "nav-item",
      icon: "fa fa-microchip",
      permission: ["CanViewClientGatewayMicroservice"],
      code: "CanViewClientGatewayMicroservice",
    },
    // {
    //   id: "payment",
    //   title: "Payment",
    //   type: "group",
    //   icon: "icon-ui",
    //   children: [
    //     {
    //       id: "payment-basic",
    //       title: "Payment",
    //       type: "collapse",
    //       icon: "fa fa-money",
    //       children: [
    {
      id: "services",
      title: "Services",
      type: "item",
      url: "/home/services",
      permission: ["CanViewService"],
      code: "CanViewService",
      icon: "fa fa-money",
    },
    {
      id: "service-groups",
      title: "Service Groups",
      type: "item",
      url: "/home/service-groups",
      permission: ["CanViewServiceGroup"],
      code: "CanViewServiceGroup",
      icon: "fa fa-money",
    },
    //       ]
    //     }
    //   ]
    // },
    // {
    //   id: "payment-gateways",
    //   title: "Payment Gateway",
    //   type: "item",
    //   url: "/home/payment-gateway",
    //   classes: "nav-item",
    //   icon: "fa fa-building",
    // },
    {
      id: "payment-gateways",
      title: "Gateway",
      type: "group",
      icon: "icon-ui",
      children: [
        {
          id: "payment-gateway",
          title: "Gateway",
          type: "collapse",
          icon: "fa fa-building",
          children: [
            {
              id: "payment-services",
              title: "Payment Gateway",
              type: "item",
              url: "/home/payment-gateway",
              permission: ["CanViewGateway"],
              code: "CanViewGateway",
            },
            {
              id: "payment-gateway-response-code-map",
              title: "Gateway Response Code Map",
              type: "item",
              url: "/home/gatewayresponse-code-map",
              permission: ["CanViewGatewayResponseCodeMap"],
              code: "CanViewGatewayResponseCodeMap",
            },
            {
              id: "payment-response-code",
              title: "Response Code",
              type: "item",
              url: "/home/responsecode",
              permission: ["CanViewResponseCode"],
              code: "CanViewResponseCode",
            },
          ],
        },
      ],
    },
    {
      id: "defaultgatewayaccout",
      title: "Default Gateway Account",
      type: "item",
      url: "/home/default-gatewayaccount",
      classes: "nav-item",
      icon: "fa fa-folder-open",
      permission: ["CanViewDefaultGatewayAccount"],
      code: "CanViewDefaultGatewayAccount",
    },
    {
      id: "microservice",
      title: "Microservices",
      type: "item",
      url: "/home/microservices",
      classes: "nav-item",
      icon: "fa fa-microchip",
      permission: ["CanViewMicroservice"],
      code: "CanViewMicroservice",
    },
    {
      id: "country",
      title: "Country",
      type: "item",
      url: "/home/country",
      classes: "nav-item",
      icon: "fa fa-globe",
      permission: ["CanViewCountry"],
      code: "CanViewCountry",
    },

    {
      id: "state",
      title: "State",
      type: "item",
      url: "/home/states",
      classes: "nav-item",
      icon: "fa fa-map-marker",
      permission: ["CanViewState"],
      code: "CanViewState",
    },

    {
      id: "currency",
      title: "Currency",
      type: "item",
      url: "/home/currency",
      classes: "nav-item",
      icon: "fa fa-dollar",
      permission: ["CanViewCountry"],
      code: "CanViewCountry",
    },
    {
      id: "payment-currency-pair",
      title: "Currency Pair",
      type: "item",
      url: "/home/currencypair",
      classes: "nav-item",
      icon: "fa fa-money",
      permission: ["CanViewCurrencyPair"],
      code: "CanViewCurrencyPair",
    },
    {
      id: "bank",
      title: "Bank",
      type: "item",
      url: "/home/bank",
      classes: "nav-item",
      icon: "fa fa-bank",
      permission: ["CanViewBank"],
      code: "CanViewBank",
    },
    {
      id: "holding-accout",
      title: "Holding Account",
      type: "item",
      url: "/home/holding-account",
      classes: "nav-item",
      icon: "fa fa-id-card-o",
      permission: ["CanViewHoldingAccount"],
      code: "CanViewHoldingAccount",
    },
    {
      id: "kyc-level",
      title: "KYC Service Level",
      type: "item",
      url: "/home/kycservice-level",
      classes: "nav-item",
      icon: "fa fa-snowflake-o",
      permission: ["CanViewKYCServiceLevel"],
      code: "CanViewKYCServiceLevel",
    },
    {
      id: "general-ledger",
      title: "General Ledger Configuration",
      type: "item",
      url: "/home/general-ledger",
      classes: "nav-item",
      icon: "fa fa-money",
      permission: ["CanViewGLConfiguration"],
      code: "CanViewGLConfiguration",
    },
    {
      id: "platform-charge",
      title: "Platform Charge",
      type: "item",
      url: "/home/platform-charge",
      classes: "nav-item",
      icon: "fa fa-gg-circle",
      permission: ["CanViewPlatformCharge"],
      code: "CanViewPlatformCharge",
    },
    {
      id: "gateway-charge",
      title: "Gateway Charge",
      type: "item",
      url: "/home/gateway-charge",
      classes: "nav-item",
      icon: "fa fa-gavel",
      permission: ["CanViewGatewayCharge"],
      code: "CanViewGatewayCharge",
    },
    {
      id: "maker-checker",
      title: "Maker Checker",
      type: "item",
      url: "/home/maker-checker",
      classes: "nav-item",
      icon: "fa fa-gavel",
      permission: ["CanViewSettlementProfile"],
      code: "CanViewSettlementProfile",
    },
    // {
    //   id: "settlement-profile",
    //   title: "Settlement Profile",
    //   type: "item",
    //   url: "/home/settlement-profile",
    //   classes: "nav-item",
    //   icon: "fa fa-gavel",
    //   permission: ["CanViewSettlementProfile"],
    //   code: "CanViewSettlementProfile",
    // },
    {
      id: "ui-usermanagement",
      title: "UI ELEMENT",
      type: "group",
      icon: "icon-ui",
      permission: ["CanViewUser"],
      code: "CanViewUser",
      children: [
        {
          id: "user-basic",
          title: "User Management",
          type: "collapse",
          icon: "fa fa-user",
          children: [
            {
              id: "user",
              title: "Users",
              type: "item",
              url: "/home/users",
              permission: ["CanViewUser"],
              code: "CanViewUser",
            },
            {
              id: "roles",
              title: "Roles",
              type: "item",
              url: "/home/roles",
              permission: ["CanViewRole"],
              code: "CanViewRole",
            },
          ],
        },
      ],
    },
    {
      id: "audit-trail",
      title: "Audit Trail",
      type: "item",
      url: "/home/audit-trail",
      classes: "nav-item",
      icon: "fa fa-tasks",
      permission: ["CanViewAuditTrail"],
      code: "CanViewAuditTrail",
    },
    // {
    //   id: "settlement",
    //   title: "Settlement",
    //   type: "item",
    //   url: "/home/audit-trail",
    //   classes: "nav-item",
    //   icon: "fa fa-bank",
    //   permission: ["CanViewAuditTrail"],
    //   code: "CanViewAuditTrail",
    // },
  ],
};

import React, { useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { appHelpers } from 'appHelpers';
import { ErrorPage } from 'Pages/NotFound/ErrorPage';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function VerifyEmail(props) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [isError] = React.useState(false)

  const handleClose = () => {
    setOpen(false);
  };
  // const handleToggle = () => {
  //   setOpen(!open);
  // };


  useEffect(() => {
    console.log("props in VerifyEmail", props);
    const { location } = props
    if (location) {
      const { pathname } = props.location

      const token = pathname.split("/")
      if (token) {
        const payload = {
          token: token[2]
        }
        verifyUser(payload)
      } else {
        // token invalid
      }
    }
  })



  const verifyUser = (payload) => {
    const { Constants } = props
    const LoginService = props.Service(null, null)

    LoginService.createItem(payload, Constants.USER, Constants.VERIFY_USER)
      .then((res) => {
        const { data } = res;
        if (data.requestSuccessful === true) {
          const { responseData } = data
          if (responseData.isConfirmed === true) {
            props.history.push("/")

          } else {
            // rediret user to create set password (setup account)
            setOpen(false)
            props.history.push({
              pathname: "/setup-account",
              state: { email: responseData.email },
            });
          }
        } else {
          setOpen(false)
          const { message } = data;
          if (message) {
            let customMessage = `An Error Occurred while processing, please try again.`;
            appHelpers.alertError(message ? message : customMessage)
          }
          props.history.push("/")
        }

      })
      .catch((err) => {
        if (err.response === undefined) {
          appHelpers.alertError("Please check your network then refresh the page again", 4500)
        } else {
          appHelpers.alertError("An Error in Verification, please ask an admin to resend the email");
          props.history.push("/")
        }

      })
  }

  return (
    <div>
      {isError === false && <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>}
      {isError === true &&
        <ErrorPage {...props} showRefresh={true} />
      }
    </div>
  );
}

import actionTypes from "../actions/actionTypes";

const initialState = {
  allusers: [],
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        allusers: action.payload.allusers,
      };

    default:
      return state;
  }
};

export default usersReducer;

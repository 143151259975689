import actionTypes from '../actions/actionTypes';

const initialState = {
    states: []
};

const statesReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_STATES_SUCCESS:
            return {
                ...state,
                states: action.payload.states,
            };


        default:
            return state;
    }
};

export default statesReducer;
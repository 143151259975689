import React from "react";
import {
  // fade,
  // withStyles,
  // createMuiTheme,
} from "@material-ui/core/styles";
// import InputBase from "@material-ui/core/InputBase";
// import { green } from "@material-ui/core/colors";
import { Form } from "react-bootstrap";
import { appHelpers } from "appHelpers";

// const BootstrapInput = withStyles((theme) => ({
//   root: {
//     "label + &": {
//       marginTop: theme.spacing(3),
//     },
//   },
//   input: {
//     borderRadius: 4,
//     position: "relative",
//     backgroundColor: theme.palette.common.white,
//     border: "1px solid #ced4da",
//     fontSize: 16,
//     width: "239px",
//     padding: "10px 12px",
//     transition: theme.transitions.create(["border-color", "box-shadow"]),
//     // Use the system font instead of the default Roboto font.
//     fontFamily: [
//       "-apple-system",
//       "BlinkMacSystemFont",
//       '"Segoe UI"',
//       "Roboto",
//       '"Helvetica Neue"',
//       "Arial",
//       "sans-serif",
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(","),
//     "&:focus": {
//       boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//       borderColor: theme.palette.primary.main,
//     },
//   },
// }))(InputBase);

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//     flexWrap: "wrap",
//   },
//   margin: {
//     margin: theme.spacing(1),
//   },
// }));

// const theme = createMuiTheme({
//   palette: {
//     primary: green,
//   },
// });

export default function CustomInput(props) {
  // const classes = useStyles();
  let type;
  if (props.type === "password") {
    type = "password"
  } else if (props.type === "text") {
    type = "text"
  } else if (props.type === "number") {
    type = "number"
  } else {
    type = "text"
  }
  return (
    <div className="input-group mb-2" style={{ background: "0" }}>
      <Form.Group
        controlId={props.id ? props.id : appHelpers.genClientRef(10)}
        style={{ width: "-webkit-fill-available" }}
      >
        <Form.Label style={{ float: "left" }}>{props.labelText}</Form.Label>
        <Form.Control
          type={type}
          isInvalid={props.showError}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
          style={{ background: "#FFFFFF" }}
          disabled={props.disabled}
        />
        <Form.Control.Feedback
          type="invalid"
          style={{ marginBottom: "-1rem", textAlign: "left" }}
        >
          {props.errorText}
        </Form.Control.Feedback>

        <div
        // style={{
        //   color: "#261B64",
        //   fontWeight: "bold",
        //   cursor: "pointer",
        //   position: "absolute",
        //   right: "15px",
        //   top: "39px",
        // }}
        >
          {props.text}
        </div>
        <div
          style={{
            position: "absolute",
            right: "15px",
            top: "39px",
            cursor: "pointer",
          }}
        >
          {props.endIcon}
        </div>
      </Form.Group>
    </div>
  );
}

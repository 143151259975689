import actionTypes from '../actions/actionTypes';

const initialState = {
    currencypair: []
};

const currencypairReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_CURRENCY_PAIR_SUCCESS:
            return {
                ...state,
                currencypair: action.payload.currencypair,
            };


        default:
            return state;
    }
};

export default currencypairReducer;
import actionTypes from '../actions/actionTypes';

const initialState = {
    chargetypes: []
};

const platformchargeReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_PLATFORM_CHARGE_TYPES_SUCCESS:
            return {
                ...state,
                chargetypes: action.payload.chargetypes,
            };


        default:
            return state;
    }
};

export default platformchargeReducer;
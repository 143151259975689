import actionTypes from "../actions/actionTypes";

const initialState = {
  clientConfigurations: [],
};

const clientConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CLIENT_CONFIGURATIONS:
      return {
        ...state,
        clientConfigurations: action.payload.clientConfigurations,
      };

    default:
      return state;
  }
};

export default clientConfigurationReducer;

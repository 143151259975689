import actionTypes from '../actions/actionTypes';

const initialState = {
    microservices: []
};

const microservicesReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_MICROSERVICES_SUCCESS:
            return {
                ...state,
                microservices: action.payload.microservices,
            };


        default:
            return state;
    }
};

export default microservicesReducer;
import React from "react";
import $ from "jquery";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const DashboardDefault = React.lazy(() => import("./Pages/Dashboard/Default"));

const PaymentService = React.lazy(() =>
  import("./Pages/PaymentService/PaymentService")
);
const Clients = React.lazy(() => import("./Pages/Clients/Clients"));
const ClientGatewayMicroservice = React.lazy(() =>
  import("./Pages/ClientGatewayMicroservice/ClientGatewayMicroservice")
);
const PaymentServiceGroup = React.lazy(() =>
  import("./Pages/PaymentServiceGroup/PaymentServiceGroup")
);
const PaymentGateway = React.lazy(() =>
  import("./Pages/Gateway/PaymentGateway/PaymentGateway")
);

const MicroServices = React.lazy(() =>
  import("./Pages/MicroServices/MicroServices")
);
const CurrencyPair = React.lazy(() =>
  import("./Pages/CurrencyPair/CurrencyPair")
);
const ResponseCode = React.lazy(() =>
  import("./Pages/ResponseCode/ResponseCode")
);
const GatewayResponseCodeMap = React.lazy(() =>
  import("./Pages/GatewayResponseCodeMap/GatewayResponseCodeMap")
);
const Country = React.lazy(() => import("./Pages/Country/Country"));
const States = React.lazy(() => import("./Pages/States/States"));
const Currency = React.lazy(() => import("./Pages/Currency/Currency"));

const Users = React.lazy(() => import("./Pages/Users/Users"));
const Roles = React.lazy(() => import("./Pages/Roles/Roles"));
const UserProfile = React.lazy(() => import("./Pages/UserProfile/UserProfile"));
const DefaultGatewayAccount = React.lazy(() =>
  import("./Pages/DefaultGatewayAccount/DefaultGatewayAccount")
);
const AuditTrail = React.lazy(() => import("./Pages/AuditTrail/AuditTrail"));

const Bank = React.lazy(() => import("./Pages/Bank/Bank"));
const HoldingAccount = React.lazy(() =>
  import("./Pages/HoldingAccount/HoldingAccount")
);
const KycLevelConfig = React.lazy(() =>
  import("./Pages/KycLevelConfig/KycLevelConfig")
);
const PlatformCharge = React.lazy(() =>
  import("./Pages/PlatformCharge/PlatformCharge")
);
const GatewayCharge = React.lazy(() =>
  import("./Pages/GatewayCharge/GatewayCharge")
);
// const SettlementProfile = React.lazy(() =>
//   import("./Pages/SettlementProfile/SettlementProfile")
// );

const MakerChecker = React.lazy(() =>
  import("./Pages/MakerChecker/MakerChecker")
);

const GLConfiguration = React.lazy(() =>
  import("./Pages/GLConfiguration/GLConfiguration")
);

const routes = [
  {
    path: "/home/dashboard",
    exact: true,
    name: "Default",
    component: DashboardDefault,
  },
  {
    path: "/home/services",
    exact: true,
    name: "Default",
    component: PaymentService,
  },
  { path: "/home/clients", exact: true, name: "Default", component: Clients },
  {
    path: "/home/client-gateway-microservice",
    exact: true,
    name: "Default",
    component: ClientGatewayMicroservice,
  },
  {
    path: "/home/service-groups",
    exact: true,
    name: "Default",
    component: PaymentServiceGroup,
  },
  {
    path: "/home/payment-gateway",
    exact: false,
    name: "Default",
    component: PaymentGateway,
  },
  {
    path: "/home/currencypair",
    exact: true,
    name: "Default",
    component: CurrencyPair,
  },
  {
    path: "/home/responsecode",
    exact: true,
    name: "Default",
    component: ResponseCode,
  },
  {
    path: "/home/default-gatewayaccount",
    exact: true,
    name: "Default",
    component: DefaultGatewayAccount,
  },
  {
    path: "/home/microservices",
    exact: true,
    name: "Default",
    component: MicroServices,
  },
  {
    path: "/home/gatewayresponse-code-map",
    exact: true,
    name: "Default",
    component: GatewayResponseCodeMap,
  },
  {
    path: "/home/country",
    exact: true,
    name: "Default",
    component: Country,
  },
  {
    path: "/home/states",
    exact: true,
    name: "Default",
    component: States,
  },
  {
    path: "/home/currency",
    exact: true,
    name: "Default",
    component: Currency,
  },
  {
    path: "/home/bank",
    exact: true,
    name: "Bank",
    component: Bank,
  },
  {
    path: "/home/holding-account",
    exact: true,
    name: "HoldingAccount",
    component: HoldingAccount,
  },
  {
    path: "/home/kycservice-level",
    exact: true,
    name: "KycLevelConfig",
    component: KycLevelConfig,
  },
  {
    path: "/home/general-ledger",
    exact: true,
    name: "GLConfiguration",
    component: GLConfiguration,
  },
  {
    path: "/home/platform-charge",
    exact: true,
    name: "PlatformCharge",
    component: PlatformCharge,
  },
  {
    path: "/home/gateway-charge",
    exact: true,
    name: "GatewayCharge",
    component: GatewayCharge,
  },
  {
    path: "/home/maker-checker",
    exact: true,
    name: "MakerChecker",
    component: MakerChecker,
  },
  // {
  //   path: "/home/settlement-profile",
  //   exact: true,
  //   name: "SettlementProfile",
  //   component: SettlementProfile,
  // },
  // {
  //   path: "/home/gateway-credential-keys",
  //   exact: true,
  //   name: "Default",
  //   component: GatewayCredentialKey,
  // },

  { path: "/home/users", exact: true, name: "Default", component: Users },
  { path: "/home/roles", exact: true, name: "Default", component: Roles },
  {
    path: "/home/userprofile",
    exact: true,
    name: "Default",
    component: UserProfile,
  },
  {
    path: "/home/audit-trail",
    exact: true,
    name: "Default",
    component: AuditTrail,
  },

  // Route for tabs

  {
    path: "/home/clients/info",
    exact: true,
    name: "ClientInfo",
    component: Clients,
  },
  {
    path: "/home/clients/users",
    exact: true,
    name: "ClientUsers",
    component: Clients,
  },
  {
    path: "/home/clients/transactions",
    exact: true,
    name: "ClientTransactions",
    component: Clients,
  },
  {
    path: "/home/clients/terminal",
    exact: true,
    name: "Terminal",
    component: Clients,
  },
  {
    path: "/home/clients/bankdestination",
    exact: true,
    name: "BankDestination",
    component: Clients,
  },
  {
    path: "/home/clients/service",
    exact: true,
    name: "ClientService",
    component: Clients,
  },
  {
    path: "/home/clients/gatewayaccount",
    exact: true,
    name: "GatewayAccount",
    component: Clients,
  },
  {
    path: "/home/clients/configurationprofile",
    exact: true,
    name: "ConfigurationPofile",
    component: Clients,
  },
  {
    path: "/home/clients/gatewaycorridor",
    exact: true,
    name: "GatwayCorridor",
    component: Clients,
  },
  {
    path: "/home/clients/collections",
    exact: true,
    name: "ClientTransactions",
    component: Clients,
  },
  {
    path: "/home/clients/clientcredentials",
    exact: true,
    name: "ClientCredentials",
    component: Clients,
  },

  //route for payment gateway
  {
    path: "/home/payment-gateway/info",
    exact: true,
    name: "PaymentGatewayInfo",
    component: PaymentGateway,
  },

  {
    path: "/home/payment-gateway/gateway-credential-key",
    exact: true,
    name: "GatewayCredentialKey",
    component: PaymentGateway,
  },
];

export default routes;

export const permissionstuff = {
  isAuthorized: (all) => {
    const permissions = {
      // client
      CanCreateClient: all.includes("CanCreateClient") ? true : false,
      CanUpdateClient: all.includes("CanUpdateClient") ? true : false,
      CanViewClient: all.includes("CanViewClient") ? true : false,

      //  client user

      // client gateway microservice
      CanCreateClientGatewayMicroservice: all.includes(
        "CanCreateClientGatewayMicroservice"
      )
        ? true
        : false,
      CanUpdateClientGatewayMicroservice: all.includes(
        "CanUpdateClientGatewayMicroservice"
      )
        ? true
        : false,
      CanViewClientGatewayMicroservice: all.includes(
        "CanViewClientGatewayMicroservice"
      )
        ? true
        : false,

      //  client service
      CanCreateClientService: all.includes("CanCreateClientService")
        ? true
        : false,
      CanUpdateClientService: all.includes("CanUpdateClientService")
        ? true
        : false,
      CanViewClientService: all.includes("CanViewClientService") ? true : false,

      // client configuration
      CanCreateConfiguration: all.includes("CanCreateConfiguration")
        ? true
        : false,
      CanUpdateConfiguration: all.includes("CanUpdateConfiguration")
        ? true
        : false,
      CanViewConfiguration: all.includes("CanViewConfiguration") ? true : false,

      // country
      CanCreateCountry: all.includes("CanCreateCountry") ? true : false,

      CanUpdateCountry: all.includes("CanUpdateCountry") ? true : false,
      CanViewCountry: all.includes("CanViewCountry") ? true : false,

      // default gateway account
      CanCreateDefaultGatewayAccount: all.includes(
        "CanCreateDefaultGatewayAccount"
      )
        ? true
        : false,

      CanUpdateDefaultGatewayAccount: all.includes(
        "CanUpdateDefaultGatewayAccount"
      )
        ? true
        : false,
      CanViewDefaultGatewayAccount: all.includes("CanViewDefaultGatewayAccount")
        ? true
        : false,

      // currency
      CanCreateCurrency: all.includes("CanCreateCurrency") ? true : false,
      CanDeleteCountry: all.includes("CanDeleteCountry") ? true : false,
      CanUpdateCurrency: all.includes("CanUpdateCurrency") ? true : false,
      CanViewCurrency: all.includes("CanViewCurrency") ? true : false,
      // currency pair
      CanCreateCurrencyPair: all.includes("CanCreateCurrencyPair")
        ? true
        : false,
      CanUpdateCurrencyPair: all.includes("CanUpdateCurrencyPair")
        ? true
        : false,
      CanViewCurrencyPair: all.includes("CanViewCurrencyPair") ? true : false,
      // destinatino bank
      CanCreateDestinationBank: all.includes("CanCreateBankDestination")
        ? true
        : false,
      CanUpdateDestinationBank: all.includes("CanUpdateBankDestination")
        ? true
        : false,
      CanViewDestinationBank: all.includes("CanViewBankDestination")
        ? true
        : false,
      //audit trail
      CanViewAuditTrail: all.includes("CanViewAuditTrail") ? true : false,
      // gateway
      CanCreateGateway: all.includes("CanCreateGateway") ? true : false,
      CanUpdateGateway: all.includes("CanUpdateGateway") ? true : false,
      CanViewGateway: all.includes("CanViewGateway") ? true : false,
      // gateway account
      CanCreateGatewayAccount: all.includes("CanCreateGatewayAccount")
        ? true
        : false,
      CanUpdateGatewayAccount: all.includes("CanUpdateGatewayAccount")
        ? true
        : false,
      CanViewGatewayAccount: all.includes("CanViewGatewayAccount")
        ? true
        : false,
      // gateway account currency pair
      CanCreateGatewayAccountCurrencyPair: all.includes(
        "CanCreateGatewayAccountCurrencyPair"
      )
        ? true
        : false,
      CanUpdateGatewayAccountCurrencyPair: all.includes(
        "CanUpdateGatewayAccountCurrencyPair"
      )
        ? true
        : false,
      CanViewGatewayAccountCurrencyPair: all.includes(
        "CanViewGatewayAccountCurrencyPair"
      )
        ? true
        : false,
      // gateway credential key
      CanCreateGatewayCredentialKey: all.includes(
        "CanCreateGatewayCredentialKey"
      )
        ? true
        : false,
      CanDeleteGatewayCredentialKey: all.includes(
        "CanDeleteGatewayCredentialKey"
      )
        ? true
        : false,
      CanUpdateGatewayCredentialKey: all.includes(
        "CanUpdateGatewayCredentialKey"
      )
        ? true
        : false,
      CanViewGatewayCredentialKey: all.includes("CanViewGatewayCredentialKey")
        ? true
        : false,
      // gateway response code map
      CanCreateGatewayResponseCodeMap: all.includes(
        "CanCreateGatewayResponseCodeMap"
      )
        ? true
        : false,
      CanDeleteGatewayResponseCodeMap: all.includes(
        "CanDeleteGatewayResponseCodeMap"
      )
        ? true
        : false,
      CanUpdateGatewayResponseCodeMap: all.includes(
        "CanUpdateGatewayResponseCodeMap"
      )
        ? true
        : false,
      CanViewGatewayResponseCodeMap: all.includes(
        "CanViewGatewayResponseCodeMap"
      )
        ? true
        : false,

      //kyc

      CanCreateKYCServiceLevel: all.includes("CanCreateKYCServiceLevel")
        ? true
        : false,

      CanViewKYCServiceLevel: all.includes("CanViewKYCServiceLevel")
        ? true
        : false,

      CanUpdateKYCServiceLevel: all.includes("CanUpdateKYCServiceLevel")
        ? true
        : false,

      CanDeleteKYCServiceLevel: all.includes("CanDeleteKYCServiceLevel")
        ? true
        : false,

      // collection
      CanViewCollection: all.includes("CanViewCollection") ? true : false,

      //  response code
      CanCreateResponseCode: all.includes("CanCreateResponseCode")
        ? true
        : false,
      CanDeleteResponseCode: all.includes("CanDeleteResponseCode")
        ? true
        : false,
      CanUpdateResponseCode: all.includes("CanUpdateResponseCode")
        ? true
        : false,
      CanViewResponseCode: all.includes("CanViewResponseCode") ? true : false,
      //  role
      CanCreateRole: all.includes("CanCreateRole") ? true : false,
      CanUpdateRole: all.includes("CanUpdateRole") ? true : false,
      CanViewRole: all.includes("CanViewRole") ? true : false,

      //Terminal

      CanCreateTerminal: all.includes("CanCreateTerminal") ? true : false,
      CanUpdateTerminal: all.includes("CanUpdateTerminal") ? true : false,
      CanViewTerminal: all.includes("CanViewTerminal") ? true : false,

      //Bank

      CanCreateBank: all.includes("CanCreateBank") ? true : false,
      CanUpdateBank: all.includes("CanUpdateBank") ? true : false,
      CanViewBank: all.includes("CanViewBank") ? true : false,

      //State
      CanCreateState: all.includes("CanCreateState") ? true : false,
      CanUpdateState: all.includes("CanUpdateState") ? true : false,
      CanViewState: all.includes("CanViewState") ? true : false,
      CanDeleteState: all.includes("CanDeleteState") ? true : false,

      //Holding Account

      CanCreateHoldingAccount: all.includes("CanCreateHoldingAccount")
        ? true
        : false,
      CanUpdateHoldingAccount: all.includes("CanUpdateHoldingAccount")
        ? true
        : false,
      CanViewHoldingAccount: all.includes("CanViewHoldingAccount")
        ? true
        : false,

      //KYCServiceLevel

      // CanCreateKYCServiceLevel: all.includes("CanCreateKYCServiceLevel")
      //   ? true
      //   : false,
      // CanUpdateKYCServiceLevel: all.includes("CanUpdateKYCServiceLevel")
      //   ? true
      //   : false,
      // CanViewKYCServiceLevel: all.includes("CanViewKYCServiceLevel")
      //   ? true
      //   : false,
      // CanDeleteKYCServiceLevel: all.includes("CanDeleteKYCServiceLevel")
      //   ? true
      //   : false,

      //SettlementProfile

      CanCreateSettlementProfile: all.includes("CanCreateSettlementProfile")
        ? true
        : false,
      CanUpdateSettlementProfile: all.includes("CanUpdateSettlementProfile")
        ? true
        : false,
      CanViewSettlementProfile: all.includes("CanViewSettlementProfile")
        ? true
        : false,
      CanDeleteSettlementProfile: all.includes("CanDeleteSettlementProfile")
        ? true
        : false,
      //Platform Charge

      CanCreatePlatformCharge: all.includes("CanCreatePlatformCharge")
        ? true
        : false,
      CanUpdatePlatformCharge: all.includes("CanUpdatePlatformCharge")
        ? true
        : false,
      CanViewPlatformCharge: all.includes("CanViewPlatformCharge")
        ? true
        : false,
      CanDeletePlatformCharge: all.includes("CanDeletePlatformCharge")
        ? true
        : false,

      //Gateway Charge

      CanCreateGatewayCharge: all.includes("CanCreateGatewayCharge")
        ? true
        : false,
      CanUpdateGatewayCharge: all.includes("CanUpdateGatewayCharge")
        ? true
        : false,
      CanViewGatewayCharge: all.includes("CanViewGatewayCharge") ? true : false,
      CanDeleteGatewayCharge: all.includes("CanDeleteGatewayCharge")
        ? true
        : false,

      //  service
      CanCreateService: all.includes("CanCreateService") ? true : false,
      CanUpdateService: all.includes("CanUpdateService") ? true : false,
      CanViewService: all.includes("CanViewService") ? true : false,
      //  service group
      CanCreateServiceGroup: all.includes("CanCreateServiceGroup")
        ? true
        : false,
      CanUpdateServiceGroup: all.includes("CanUpdateServiceGroup")
        ? true
        : false,
      CanViewServiceGroup: all.includes("CanViewServiceGroup") ? true : false,
      // transaction
      CanCreateTransaction: all.includes("CanCreateTransaction") ? true : false,
      CanViewTransaction: all.includes("CanViewTransaction") ? true : false,
      //  user
      CanCreateUser: all.includes("CanCreateUser") ? true : false,
      CanUpdateUser: all.includes("CanUpdateUser") ? true : false,
      CanViewUser: all.includes("CanViewUser") ? true : false,
      //general ledger
      CanCreateGLConfiguration: all.includes("CanCreateGLConfiguration")
        ? true
        : false,
      CanUpdateGLConfiguration: all.includes("CanUpdateGLConfiguration")
        ? true
        : false,
      CanViewGLConfiguration: all.includes("CanViewGLConfiguration")
        ? true
        : false,

      //microservice
      CanViewMicroservice: all.includes("CanViewMicroservice") ? true : false,
      CanCreateMicroservice: all.includes("CanCreateMicroservice")
        ? true
        : false,
      CanUpdateMicroservice: all.includes("CanUpdateMicroservice")
        ? true
        : false,
      CanDeleteMicroservice: all.includes("CanDeleteMicroservice")
        ? true
        : false,
    };
    return permissions;
  },

  filterSideMenu: (all, items) => {
    var res = items.filter(function f(o) {
      // if (o.permission && o.permission.includes("CanViewRole")) return true
      if (all.includes(o.code)) return true;

      if (o.children) {
        return (o.children = o.children.filter(f)).length;
      }
      return res;
    });

    return res;
  },
};

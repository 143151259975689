import { appHelpers } from "appHelpers";

const actionTypes = {
  APP_LOAD: "APP_LOAD",
  BEGIN_REQUEST: "BEGIN_REQUEST",
  COLLAPSE_MENU: "COLLAPSE_MENU",
  COLLAPSE_TOGGLE: "COLLAPSE_TOGGLE",
  FULL_SCREEN: "FULL_SCREEN",
  FULL_SCREEN_EXIT: "FULL_SCREEN_EXIT",
  CHANGE_LAYOUT: "CHANGE_LAYOUT",
  NAV_CONTENT_LEAVE: "NAV_CONTENT_LEAVE",
  NAV_COLLAPSE_LEAVE: "NAV_COLLAPSE_LEAVE",
  UPDATE_NAV_HEADER_COLOR: "UPDATE_NAV_HEADER_COLOR",
  CHANGE_TOP_NAV_MENU_ITEM: "CHANGE_TOP_NAV_MENU_ITEM",
  TOGGLE_CHART_AND_DATA_UI: "TOGGLE_CHART_AND_DATA_UI",
  GET_MICROSERVICES_SUCCESS: "GET_MICROSERVICES_SUCCESS",
  GET_PAYMENT_SERVICE_GROUPS_SUCCESS: "GET_PAYMENT_SERVICE_GROUPS_SUCCESS",
  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  // GET_COUNTRY_SUCCESS: "GET_COUNTRY_SUCCESS",
  GET_CLIENTSERVICES_SUCCESS: "GET_CLIENTSERVICES_SUCCESS",
  GET_CLIENT_CONFIGURATIONS: "GET_CLIENT_CONFIGURATIONS",
  GET_GATEWAYS_SUCCESS: "GET_GATEWAYS_SUCCESS",
  GET_GATEWAY_CREDENTIAL_KEY_SUCCESS: "GET_GATEWAY_CREDENTIAL_KEY_SUCCESS",
  GET_CLIENTS_SUCCESS: "GET_CLIENTS_SUCCESS",
  GET_SERVICES_SUCCESS: "GET_SERVICES_SUCCESS",
  GET_ALL_CONFIGURATIONS_SUCCESS: "GET_ALL_CONFIGURATIONS_SUCCESS",
  GET_RESPONSE_CODE_SUCCESS: "GET_RESPONSE_CODE_SUCCESS",
  GET_GATEWAY_ACCOUNTS_BY_CLIENT: "GET_GATEWAY_ACCOUNTS_BY_CLIENT",
  GET_CURRENCY_PAIR_SUCCESS: "GET_CURRENCY_PAIR_SUCCESS",
  GET_CURRENCY_SUCCESS: "GET_CURRENCY_SUCCESS",
  GET_PERMISSIONS_SUCCESS: "GET_PERMISSIONS_SUCCESS",
  GET_BANK_SUCCESS: "GET_BANK_SUCCESS",
  SAVE_USER_SUCCESS: "SAVE_USER_SUCCESS",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_GATEWAYS_BY_CLIENT: "GET_GATEWAYS_BY_CLIENT",
  SET_EXPIRATION: "SET_EXPIRATION",
  GET_PLATFORM_CHARGE_TYPES_SUCCESS: "GET_PLATFORM_CHARGE_TYPES_SUCCESS",
  GET_GATEWAY_CHARGE_TYPES_SUCCESS: "GET_GATEWAY_CHARGE_TYPES_SUCCESS",
  GET_STATES_SUCCESS: "GET_STATES_SUCCESS",
  GET_KYC_LEVELS_SUCCESS: "GET_KYC_LEVELS_SUCCESS",
  GET_GATEWAY_CHARGE_BEARER_SUCCESS: "GET_GATEWAY_CHARGE_BEARER_SUCCESS",
  GET_SETTLEMENT_TRANSACTIONS_PENDING: "GET_SETTLEMENT_TRANSACTIONS_PENDING",
  GET_SETTLEMENT_TRANSACTIONS_SUCCESS: "GET_SETTLEMENT_TRANSACTIONS_SUCCESS",
  POST_SETTLEMENT_TRANSACTIONS_PENDING: "POST_SETTLEMENT_TRANSACTIONS_PENDING",
  POST_SETTLEMENT_TRANSACTIONS_SUCCESS: "POST_SETTLEMENT_TRANSACTIONS_SUCCESS",
  POST_SETTLEMENT_TRANSACTIONS_ERROR: "POST_SETTLEMENT_TRANSACTIONS_ERROR",
  SAVE_CURRENT_CLIENT_SUCCESS: "SAVE_CURRENT_CLIENT_SUCCESS",
  SAVE_CLIENT_NAVIGATION_KEY: "SAVE_CLIENT_NAVIGATION_KEY",
  SAVE_TEST_DATA_SUCCESS: "SAVE_TEST_DATA_SUCCESS",
  SAVE_CURRENT_GATEWAY_SUCCESS: "SAVE_CURRENT_GATEWAY_SUCCESS",
};

// add as many actionTypes as necessary. (But be careful of memory leakages)
export const saveClientNavigationKeySuccess = (clientNavigationKey) => ({
  type: actionTypes.SAVE_CLIENT_NAVIGATION_KEY,
  payload: { clientNavigationKey },
});

export const saveexpirationSuccess = (expiresIn) => ({
  type: actionTypes.SET_EXPIRATION,
  payload: { expiresIn },
});
export const saveuserDataSuccess = (userdata) => ({
  type: actionTypes.SAVE_USER_SUCCESS,
  payload: { userdata },
});

export const savecurrentClientSuccess = (currentBusiness) => ({
  type: actionTypes.SAVE_CURRENT_CLIENT_SUCCESS,
  payload: { currentBusiness },
});
export const savetestDataSuccess = (testData) => ({
  type: actionTypes.SAVE_TEST_DATA_SUCCESS,
  payload: { testData },
});
export const savecurrentGatewaySuccess = (currentGateway) => ({
  type: actionTypes.SAVE_CURRENT_GATEWAY_SUCCESS,
  payload: { currentGateway },
});

export const fetchRolesSuccess = (allroles) => ({
  type: actionTypes.GET_ROLES_SUCCESS,
  payload: { allroles },
});
export const fetchGatewayChargeBearerSuccess = (gatewaychargebearer) => ({
  type: actionTypes.GET_GATEWAY_CHARGE_BEARER_SUCCESS,
  payload: { gatewaychargebearer },
});
export const fetchUsersSuccess = (allusers) => ({
  type: actionTypes.GET_USER_SUCCESS,
  payload: { allusers },
});
export const fetchStatesSuccess = (states) => ({
  type: actionTypes.GET_STATES_SUCCESS,
  payload: { states },
});
export const fetchPermissionsSuccess = (allpermissions) => ({
  type: actionTypes.GET_PERMISSIONS_SUCCESS,
  payload: { allpermissions },
});

export const fetchCurrencySuccess = (currency) => ({
  type: actionTypes.GET_CURRENCY_SUCCESS,
  payload: { currency },
});

export const fetchPlatformChargeTypeSuccess = (chargetypes) => ({
  type: actionTypes.GET_PLATFORM_CHARGE_TYPES_SUCCESS,
  payload: { chargetypes },
});
export const fetchGatewayChargeTypeSuccess = (gatewaychargetypes) => ({
  type: actionTypes.GET_GATEWAY_CHARGE_TYPES_SUCCESS,
  payload: { gatewaychargetypes },
});
export const fetchCurrencyPairSuccess = (currencypair) => ({
  type: actionTypes.GET_CURRENCY_PAIR_SUCCESS,
  payload: { currencypair },
});
export const fetchBankSuccess = (bank) => ({
  type: actionTypes.GET_BANK_SUCCESS,
  payload: { bank },
});
export const fetchGatewayAccountsByClientSuccess = (
  gatewayaccountsbyclient
) => ({
  type: actionTypes.GET_GATEWAY_ACCOUNTS_BY_CLIENT,
  payload: { gatewayaccountsbyclient },
});
export const fetchGatewaysByClientSuccess = (gatewaysbyclient) => ({
  type: actionTypes.GET_GATEWAYS_BY_CLIENT,
  payload: { gatewaysbyclient },
});

export const fetchAllResponseCodeSuccess = (responseCodes) => ({
  type: actionTypes.GET_RESPONSE_CODE_SUCCESS,
  payload: { responseCodes },
});

export const fetchAllConfigurationsSuccess = (configurations) => ({
  type: actionTypes.GET_ALL_CONFIGURATIONS_SUCCESS,
  payload: { configurations },
});
export const fetchServicesSuccess = (services) => ({
  type: actionTypes.GET_SERVICES_SUCCESS,
  payload: { services },
});

export const fetchClientsSuccess = (allclients) => ({
  type: actionTypes.GET_CLIENTS_SUCCESS,
  payload: { allclients },
});
export const fetchKycLevelsSuccess = (kyclevels) => ({
  type: actionTypes.GET_KYC_LEVELS_SUCCESS,
  payload: { kyclevels },
});
export const fetchGatewayCredentialKeysSuccess = (gatewayCredentialkeys) => ({
  type: actionTypes.GET_GATEWAY_CREDENTIAL_KEY_SUCCESS,
  payload: { gatewayCredentialkeys },
});

export const fetchGatewaysSuccess = (gateways) => ({
  type: actionTypes.GET_GATEWAYS_SUCCESS,
  payload: { gateways },
});

export const fetchClientConfigurationsSuccess = (clientConfigurations) => ({
  type: actionTypes.GET_CLIENT_CONFIGURATIONS,
  payload: { clientConfigurations },
});

export const fetchMicroServicesSuccess = (microservices) => ({
  type: actionTypes.GET_MICROSERVICES_SUCCESS,
  payload: { microservices },
});
export const fetchPaymentServiceGroupsSuccess = (servicegroups) => ({
  type: actionTypes.GET_PAYMENT_SERVICE_GROUPS_SUCCESS,
  payload: { servicegroups },
});
export const fetchCountriesSuccess = (countries) => ({
  type: actionTypes.GET_COUNTRIES_SUCCESS,
  payload: { countries },
});

// export const fetchCountrySuccess = (country) => ({
//   type: actionTypes.GET_COUNTRY_SUCCESS,
//   payload: { country },
// });
export const fetchClientServicesSuccess = (clientservices) => ({
  type: actionTypes.GET_CLIENTSERVICES_SUCCESS,
  payload: { clientservices },
});

export const fetchSettlementTransactionsPending = () => ({
  type: actionTypes.GET_SETTLEMENT_TRANSACTIONS_PENDING,
});

export const fetchSettlementTransactionsSuccess = (settlementTransactions) => ({
  type: actionTypes.GET_SETTLEMENT_TRANSACTIONS_SUCCESS,
  payload: settlementTransactions,
});

export const postSettlementTransactionsPending = () => ({
  type: actionTypes.POST_SETTLEMENT_TRANSACTIONS_PENDING,
});
export const postSettlementTransactionsSuccess = (payload) => ({
  type: actionTypes.POST_SETTLEMENT_TRANSACTIONS_SUCCESS,
  payload: payload,
});

export const postSettlementTransactionsError = (payload) => ({
  type: actionTypes.POST_SETTLEMENT_TRANSACTIONS_ERROR,
  payload: payload,
});

// Redux Actions Api Calls

export function fetchAllGatewayChargeBearer(
  ServiceBase,
  Constants,
  history,
  searchData
) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.GATEWAY_CHARGE_BEARER,
        Constants.GET_ALL,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(
                fetchGatewayChargeBearerSuccess(data.responseData.items)
              );
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllRoles(ServiceBase, Constants, history, searchData) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsFilter(
        Constants.ROLES,
        Constants.GET_ALL,
        1000,
        1,
        searchData
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchRolesSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllPermissions(
  ServiceBase,
  Constants,
  history,
  isAdmin,
  context
) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(
        Constants.PERMISSIONS,
        Constants.GET_ALL,
        1000,
        1,
        isAdmin
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchPermissionsSuccess(data.responseData));
              if (context) {
                context.setState({ showPermissionCheckboxes: false });
              }
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllCurrency(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.CURRENCY, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchCurrencySuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllPlatformChargeType(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getInfo(Constants.PLATFORM_CHARGE, Constants.GET_CHARGE_TYPE)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchPlatformChargeTypeSuccess(data.responseData));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllGatewayChargeType(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getInfo(Constants.GATEWAY_CHARGE, Constants.GET_CHARGE_TYPE)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchGatewayChargeTypeSuccess(data.responseData));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllUsers(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.GET_USER, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchUsersSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}
export function fetchAllStates(ServiceBase, Constants, history, searchData) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsFilter(
        Constants.STATE,
        Constants.GET_ALL,
        1000,
        1,
        searchData
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchStatesSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllCurrencyPair(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.CURRENCY_PAIR,
        Constants.GET_ALL,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchCurrencyPairSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllBanks(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.BANK, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchBankSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}
export function fetchAllResponseCode(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.RESPONSE_CODE,
        Constants.GET_ALL,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchAllResponseCodeSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllServices(ServiceBase, Constants, history, callback) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.SERVICES, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchServicesSuccess(data.responseData.items));
              if (callback) {
                callback();
              }
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllClients(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.CLIENTS, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchClientsSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllKycLevels(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.KYC_SERVICE_LEVEL,
        Constants.GET_KYC_LEVELS,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchKycLevelsSuccess(data.responseData));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

// with serviceId and gatewayId
export function fetchGatewayCredentialKeysServiceIdGatewayId(
  ServiceBase,
  Constants,
  history,
  idOne,
  idTwo,
  obj,
  isEdit
) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsByTwoIds(
        Constants.GATEWAY_CREDENTIAL_KEY,
        Constants.GET,
        1000,
        1,
        idOne,
        idTwo
      )

        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              if (isEdit === true) {
                obj.setState((prevState) => ({
                  clientGatewayAccountEditData: {
                    ...prevState.clientGatewayAccountEditData,
                    GatewayAccountCredentials: appHelpers.formatCredentialKeys(
                      data.responseData.items
                    ),
                  },
                }));
              } else {
                obj.setState({
                  GatewayAccountCredentials: appHelpers.formatCredentialKeys(
                    data.responseData.items
                  ),
                });
              }
              dispatch(
                fetchGatewayCredentialKeysSuccess(data.responseData.items)
              );
              obj.setState({ loadingCredentialKeys: false });
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}
//

export function fetchGatewayCredentialKeys(
  ServiceBase,
  Constants,
  history,
  id,
  obj,
  isEdit
) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(
        Constants.GATEWAY_CREDENTIAL_KEY,
        Constants.GET_BY_GATEWAY_ID,
        1000,
        1,
        id
      )

        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              if (isEdit === true) {
                obj.setState((prevState) => ({
                  clientGatewayAccountEditData: {
                    ...prevState.clientGatewayAccountEditData,
                    GatewayAccountCredentials: appHelpers.formatCredentialKeys(
                      data.responseData.items
                    ),
                  },
                }));
              } else {
                obj.setState({
                  GatewayAccountCredentials: appHelpers.formatCredentialKeys(
                    data.responseData.items
                  ),
                });
              }
              dispatch(
                fetchGatewayCredentialKeysSuccess(data.responseData.items)
              );
              obj.setState({ loadingCredentialKeys: false });
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchGateways(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.GATEWAYS, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchGatewaysSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchClientConfigurations(
  ServiceBase,
  Constants,
  history,
  clientId
) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(
        Constants.CONFIGURATION,
        Constants.GET_ALL_CONFIGURATIONS_BY_CLIENT_ID,
        1000,
        1,
        clientId
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(
                fetchClientConfigurationsSuccess(data.responseData.items)
              );
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchClientServices(ServiceBase, Constants, history, clientId) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(
        Constants.CLIENT_SERVICES,
        Constants.GET_ALL_BY_CLIENT_ID,
        1000,
        1,
        clientId
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchClientServicesSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}
export function fetchGatewayAccountsByClient(
  ServiceBase,
  Constants,
  history,
  clientId
) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(
        Constants.GATEWAY_ACCOUNT,
        Constants.GET_ALL_BY_CLIENT_ID,
        1000,
        1,
        clientId
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              const filteredGatewayAccounts = data.responseData.items.filter(
                ({ accountName, gatewayAccountNumber }) =>
                  accountName !== null && gatewayAccountNumber !== null
              );
              if (filteredGatewayAccounts.length) {
                dispatch(
                  fetchGatewayAccountsByClientSuccess(filteredGatewayAccounts)
                );
              } else {
                dispatch(fetchGatewayAccountsByClientSuccess([]));
              }
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchGatewaysByClient(
  ServiceBase,
  Constants,
  history,
  clientId
) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItemsById(
        Constants.GATEWAYS,
        Constants.GET_ALL_BY_CLIENT_ID,
        1000,
        1,
        clientId
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchGatewaysByClientSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchAllConfigurations(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.CONFIGURATION,
        Constants.GET_ALL,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchAllConfigurationsSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchCountries(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(Constants.COUNTRY, Constants.GET_ALL, 1000, 1)
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchCountriesSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

// export function fetchCountry(ServiceBase, Constants, history) {
//   return (dispatch) => {
//     return (
//       ServiceBase.getReduxItems(Constants.COUNTRY, Constants.GET_ALL, 1000, 1)
//         .then((res) => {
//           const { data } = res;
//           if (data) {
//             if (data.responseData) {
//               dispatch(fetchCountrySuccess(data.responseData.items));
//             }
//           }
//           //debugger
//           return data;
//         })
//         // .catch(error => dispatch(fetchProductsFailure(error)));
//         .catch((error) => {
//           console.log("error", error.response);

//           return error;
//         })
//     );
//   };
// }

export function fetchMicroServices(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.MICRO_SERVICES,
        Constants.GET_ALL,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(fetchMicroServicesSuccess(data.responseData.items));
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchPaymentServiceGroups(ServiceBase, Constants, history) {
  return (dispatch) => {
    return (
      ServiceBase.getReduxItems(
        Constants.SERVICE_GROUPS,
        Constants.GET_ALL,
        1000,
        1
      )
        .then((res) => {
          const { data } = res;
          if (data) {
            if (data.responseData) {
              dispatch(
                fetchPaymentServiceGroupsSuccess(data.responseData.items)
              );
            }
          }
          //debugger
          return data;
        })
        // .catch(error => dispatch(fetchProductsFailure(error)));
        .catch((error) => {
          console.log("error", error.response);

          return error;
        })
    );
  };
}

export function fetchSettlementTransactions({
  startDate,
  endDate,
  pageSize,
  pageNo,
  keyword,
}) {
  return (dispatch) => {
    dispatch(fetchSettlementTransactionsPending());

    return fetch(
      `https://vgnpc-settlement-dev.test.vggdev.com/api/v1/settlement/gettransactions?startDate=${startDate}&endDate=${endDate}&pageSize=${pageSize}&pageNumber=${pageNo}${
        keyword ? "&keyword=" + keyword : ""
      }`
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.responseData) {
          dispatch(fetchSettlementTransactionsSuccess(data.responseData));
        }
      })
      .catch((error) => {
        console.log("error", error.response);

        return error;
      });
  };
}

export function postSettlementTransactions(data) {
  return (dispatch) => {
    dispatch(postSettlementTransactionsPending());

    return fetch(
      `https://vgnpc-settlement-dev.test.vggdev.com/api/v1/settlement/settletransactions`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.responseData) {
          dispatch(postSettlementTransactionsSuccess(data));
        } else {
          dispatch(postSettlementTransactionsError(data.message));
        }
      })
      .catch((error) => {
        dispatch(postSettlementTransactionsError(error.response));
        console.log("error", error.response);

        return error;
      });
  };
}

//  other dispatches

export function saveExpiration(expiresIn) {
  return (dispatch) => {
    return dispatch(saveexpirationSuccess(expiresIn));
  };
}
export function saveUserData(data) {
  return (dispatch) => {
    return dispatch(saveuserDataSuccess(data));
  };
}

export function handlesaveCurrentClient(currentBusiness) {
  return (dispatch) => {
    return dispatch(savecurrentClientSuccess(currentBusiness));
  };
}
export function handlesaveTestData(testData) {
  return (dispatch) => {
    return dispatch(savetestDataSuccess(testData));
  };
}

export function handlesaveCurrentGateway(currentGateway) {
  return (dispatch) => {
    return dispatch(savecurrentGatewaySuccess(currentGateway));
  };
}

export function handlesaveClientNavigationKey(clientNavigationKey) {
  return (dispatch) => {
    return dispatch(saveClientNavigationKeySuccess(clientNavigationKey));
  };
}
export default actionTypes;

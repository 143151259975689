import { combineReducers, createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { appSettings } from "./reducers/appSettings";
import navigation from "./reducers/navigation";
import microservicesReducer from "./reducers/microservicesReducer";
import servicegroupReducer from "./reducers/servicegroupReducer";
import countriesReducer from "./reducers/countriesReducer";
import clientServicesReducer from "./reducers/clientServicesReducer";
import clientConfigurationReducer from "./reducers/clientConfigurationReducer";
import gatewayReducer from "./reducers/gatewayReducer";
import clientsReducer from "./reducers/clientsReducer";
import servicesReducer from "./reducers/servicesReducer";
import configurationReducer from "./reducers/configurationReducer";
import responseCodeReducer from "./reducers/responseCodeReducer";
import currencypairReducer from "./reducers/currencypairReducer";
import currencyReducer from "./reducers/currencyReducer";
import permissionsReducer from "./reducers/permissionsReducer";
import authReducer from "./reducers/authReducer";
import rolesReducer from "./reducers/rolesReducer";
import usersReducer from "./reducers/usersReducer";
import bankReducer from "./reducers/bankReducer";
import platformchargeReducer from "./reducers/platformchargeReducer";
import gatewaychargeReducer from "./reducers/gatewaychargeReducer";
import statesReducer from "./reducers/statesReducer";
import kyclevelReducer from "./reducers/kyclevelReducer";
import gatewaychargebearerReducer from "./reducers/gatewaychargebearerReducer";
import settlementTransactionsReducer from "./reducers/settlementTransactionsReducer";
import thunk from "redux-thunk";
import { promiseMiddleware } from "./middleware";
import localForage from "localforage";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
const rootReducer = combineReducers({
  appSettings,
  navigation,
  rolesReducer,
  usersReducer,
  microservicesReducer,
  servicegroupReducer,
  countriesReducer,
  clientServicesReducer,
  clientConfigurationReducer,
  configurationReducer,
  gatewayReducer,
  clientsReducer,
  servicesReducer,
  responseCodeReducer,
  currencypairReducer,
  currencyReducer,
  permissionsReducer,
  bankReducer,
  platformchargeReducer,
  authReducer,
  gatewaychargeReducer,
  statesReducer,
  kyclevelReducer,
  gatewaychargebearerReducer,
  settlementTransactionsReducer,
});

const persistConfig = {
  key: "root",
  // timeout:2000,
  storage: localForage,
  // transforms: [
  //   encryptTransform({
  //     secretKey: 'my-super-secret-key',
  //     onError: function (error) {
  //       // Handle the error.
  //     },
  //   }),
  // ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  let store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk, promiseMiddleware, logger))
  );
  let persistor = persistStore(store);
  return { store, persistor };
};

import actionTypes from '../actions/actionTypes';

const initialState = {
    gatewaychargetypes: []
};

const gatewaychargeReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_GATEWAY_CHARGE_TYPES_SUCCESS:
            return {
                ...state,
                gatewaychargetypes: action.payload.gatewaychargetypes,
            };


        default:
            return state;
    }
};

export default gatewaychargeReducer;
import React from "react";
// import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import "./customtooltip.scss";
export default function CustomToolTip({ show, content, tooltipvalue }) {
  // const classes = useStyles();

  return (
    <>
      {show && (
        <div className="ctooltip">
          {content}
          <span className="tooltiptext">{tooltipvalue}</span>
        </div>
      )}
    </>
  );
}

import actionTypes from '../actions/actionTypes';

const initialState = {
    currency: []
};

const currencyReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_CURRENCY_SUCCESS:
            return {
                ...state,
                currency: action.payload.currency,
            };


        default:
            return state;
    }
};


export default currencyReducer;
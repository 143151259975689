import React, { Suspense } from "react";
import {
  Route,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import Loader from "../Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "../ScrollToTop";
import SignIn from "Pages/Authentication/SignIn/SignIn";
import ForgotPassword from "Pages/Authentication/ForgotPassword/ForgotPassword";
import ResetPassword from "Pages/Authentication/ResetPassword/ResetPassword";
import * as CommonFunctions from "CommonFunctons";
import { NotFound } from "Pages/NotFound/NotFound";
import VerifyEmail from "Pages/Authentication/VerifyEmail/VerifyEmail";
import AuthenticatedLayout from "./AuthenticatedLayout";
import SetupAccount from "Pages/Authentication/SetupAccount/SetupAccount";

export class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // no more needed
    // let tkEncrypt = JSON.parse(localStorage.getItem("SERVICEMANAGER.UI_"));
    // let permEncrypt = JSON.parse(localStorage.getItem("SERVICEMANAGER.IU_"));

    // if (
    //   tkEncrypt &&
    //   permEncrypt &&
    //   tkEncrypt !== null &&
    //   tkEncrypt !== undefined &&
    //   permEncrypt !== undefined
    // ) {
    //   const { history } = this.props;
    //   if (
    //     history.location.pathname === "/login" ||
    //     !history.location.pathname.startsWith("/home")
    //   ) {
    //     this.props.history.push("/home/clients");
    //   } else {
    //     this.props.history.push(history.location.pathname);
    //   }
    // }
  }

  render() {
    return (
      <Aux>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Switch>
              {/* All unthenticated Routes will go here */}
              {/* login, reset-password, update-password */}

              <Route exact path="/">
                <Redirect to="/login" />
              </Route>

              <Route
                path={"/login"}
                render={(props) => (
                  <SignIn
                    {...this.props}
                    validator={CommonFunctions.validator}
                    validatorAll={CommonFunctions.validatorAll}
                  />
                )}
              />

              <Route
                path={"/forgot-password"}
                exact
                render={(props) => (
                  <ForgotPassword
                    {...this.props}
                    validator={CommonFunctions.validator}
                    validatorAll={CommonFunctions.validatorAll}
                  />
                )}
              />
              <Route
                path={"/verify-email/*"}
                exact
                render={(props) => (
                  <VerifyEmail
                    {...this.props}
                    validator={CommonFunctions.validator}
                    validatorAll={CommonFunctions.validatorAll}
                  />
                )}
              />
              <Route
                path={"/reset-password/*"}
                exact
                render={(props) => (
                  <ResetPassword
                    {...this.props}
                    validator={CommonFunctions.validator}
                    validatorAll={CommonFunctions.validatorAll}
                  />
                )}
              />

              <Route
                path={"/setup-account"}
                exact
                render={(props) => (
                  <SetupAccount
                    {...this.props}
                    validator={CommonFunctions.validator}
                    validatorAll={CommonFunctions.validatorAll}
                  />
                )}
              />

              {/* This layout with house a layout containing all authenticated routes */}
              <Route
                path="/home"
                render={(props) => (
                  <AuthenticatedLayout {...this.props} {...props} />
                )}
              />

              <Route
                render={(props) => <NotFound {...this.props} {...props} />}
              />
            </Switch>
          </Suspense>
        </ScrollToTop>
      </Aux>
    );
  }
}

export default withRouter(Layout);

import actionTypes from "../actions/actionTypes";

const initialState = {
  allTransactions: [],
  allTransactionsRes: [
    {
      id: 1,
      amount: "100",
      transactionDate: "2021-09-23T09:06:41.589Z",
      chargeReference: "jk4olk4",
      charges: "089",
      transactionStatus: 1,
      splitTransactions: [
        {
          settlementTransactionId: 0,
          accountNumber: "string",
          accountName: "string",
          bankCode: "string",
          accountType: "string",
          organization: "string",
          splitTransactionReference: "string",
          amount: 0,
          transactionStatus: 1,
          transactionDate: "2021-10-04T16:07:47.890Z",
          settlementDate: "2021-10-04T16:07:47.890Z",
          processingStatus: 0,
        },
      ],
    },
    {
      id: 2,
      amount: "100",
      transactionDate: "2021-09-23T09:06:41.589Z",
      chargeReference: "jk4olk4",
      charges: "089",
      transactionStatus: 2,
      splitTransactions: [
        {
          settlementTransactionId: 0,
          accountNumber: "string",
          accountName: "string",
          bankCode: "string",
          accountType: "string",
          organization: "string",
          splitTransactionReference: "string",
          amount: 0,
          transactionStatus: 1,
          transactionDate: "2021-10-04T16:07:47.890Z",
          settlementDate: "2021-10-04T16:07:47.890Z",
          processingStatus: 0,
        },
      ],
    },
    {
      id: 3,
      amount: "100",
      transactionDate: "2021-09-23T09:06:41.589Z",
      chargeReference: "jk4olk4",
      charges: "089",
      transactionStatus: 1,
      splitTransactions: [
        {
          settlementTransactionId: 0,
          accountNumber: "string",
          accountName: "string",
          bankCode: "string",
          accountType: "string",
          organization: "string",
          splitTransactionReference: "string",
          amount: 0,
          transactionStatus: 1,
          transactionDate: "2021-10-04T16:07:47.890Z",
          settlementDate: "2021-10-04T16:07:47.890Z",
          processingStatus: 0,
        },
      ],
    },
  ],
  loading: true,
  postSuccessful: false,
  errorMessage: null,
  successMessage: null,
};

const settlementTransactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SETTLEMENT_TRANSACTIONS_PENDING:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_SETTLEMENT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTransactions: action.payload,
      };

    case actionTypes.POST_SETTLEMENT_TRANSACTIONS_PENDING:
      return {
        ...state,
        loading: true,
        postSuccessful: false,
        errorMessage: null,
        successMessage: null,
      };

    case actionTypes.POST_SETTLEMENT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        postSuccessful: true,
        successMessage: action.payload,
      };

    case actionTypes.POST_SETTLEMENT_TRANSACTIONS_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default settlementTransactionsReducer;

import React from "react";
import { Link } from "react-router-dom";

import "../../../assets/scss/style.scss";
import Aux from "../../../components/hoc/_Aux";
import Breadcrumb from "../../../components/Breadcrumb";
import { newresetPasswordErrors } from "validation";
import CustomInput from "components/Input/CustomInput";
import { CircularProgress } from "@material-ui/core";
import Logo from "../../../assets/images/new_vigipay_light background.svg";
import { appHelpers } from "appHelpers";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      err: newresetPasswordErrors,
      data: {
        password: "",
        confirmPassword: "",
      },
      hidden: true,
      hide: true,
      isError: false,
      isSuccess: false,
      token: "",
      id: "",
      loading: false,
      disableCreate: false,
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.secondToggleShow = this.secondToggleShow.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;

    if (location) {
      const { pathname } = this.props.location;

      const token = pathname.split("/");
      if (token) {
        if (token?.[2] && token?.[3]) {
          // token very valid
          this.setState({ token: token?.[2], id: token?.[3] });
        } else {
          // token still invdalid
          appHelpers.alertWarning("Token Invalid, Reset Your Password Again", 4000);
          this.props.history.push("/forgot-password");
        }
      } else {
        // token invalid
        appHelpers.alertWarning("Token Invalid, Reset Your Password Again", 4000);
        this.props.history.push("/forgot-password");
      }
    }
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  secondToggleShow() {
    this.setState({ hide: !this.state.hide });
  }

  reset = () => {
    //
    const { Constants } = this.props;
    this.setState({ disableCreate: true, isSuccess: false, isError: false, errorText: "", loading: true });
    this.props.validatorAll(
      [
        { name: "password", value: this.state.data.password },
        {
          name: "confirmPassword",
          value: this.state.data.confirmPassword,
          password: this.state.data.password,
        },
      ],
      "ResetPassword",
      this
    );
    if (this.state.err.all.size > 0) {
      this.setState({ creating: false, disableCreate: false, loading: false, errorText: "" });
      return;
    }

    const payload = {
      userId: this.state.id,
      token: this.state.token,
      newPassword: this.state.data.password,
    };

    const ResetPasswordService = this.props.Service(null, null);

    ResetPasswordService.createItem(payload, Constants.ACCOUNT, Constants.RESET_PASSWORD)
      .then(res => {
        const { data } = res;
        if (data.responseData === true) {
          this.setState({ disableCreate: false, isSuccess: true, isError: false, loading: false, errorText: "Reset Password Successful" });
          setTimeout(() => {
            this.props.history.push("/");
          }, 1500);
          return;
        } else {
          this.setState({ disableCreate: false, isSuccess: false, isError: true, errorText: data.message, loading: false });
        }
      })
      .catch(err => {
        if (err) {
          if (err.response === undefined) {
            this.setState({ disableCreate: false, loading: false, isSuccess: false, isError: true, errorText: "Check your network, please try again" });
          }
          if (err.response) {
            this.setState({ disableCreate: false, loading: false, isSuccess: false, isError: true });
          }
        }
      });
  };
  handleCreateFormInputChange = (input, label) => ({ target: { value } }) => {
    this.setState(
      prevState => ({
        data: {
          ...prevState.data,
          [input]: value,
        },
      }),
      () => {
        this.props.validator({ name: input, value: value, label: label, password: this.state.data.password, confirmPassword: this.state.data.confirmPassword }, "ResetPassword", this);
      }
    );
  };

  render() {
    const { err, data } = this.state;
    return (
      <Aux>
        <Breadcrumb />
        <div className="auth-wrapper">
          <div className="auth-content">
            <div className="card">
              <div className="card-body text-center">
                <Link to={"/"}>
                  <img src={Logo} alt="Logo" style={{ width: "190px", height: "80px" }} />
                </Link>
                <h4 className="mb-5">
                  <b>Reset Password</b>
                </h4>
                {/* <h6
                  className="text-muted mt-3"
                  style={{ marginBottom: "2rem" }}
                >
                  Enter a new passwrd
                </h6> */}
                <div className="input-group mb-3" style={{ position: "relative" }}>
                  <CustomInput
                    errorText={err.password}
                    showError={err.password.length > 0}
                    value={data.password}
                    onChange={this.handleCreateFormInputChange("password", "Password")}
                    id="reset-password"
                    labelText="Password"
                    placeholder="password"
                    type={this.state.hidden ? "password" : "text"}
                    endIcon={
                      // <p
                      //   onClick={this.toggleShow}
                      //   text={this.state.hidden ? "show" : "hide"}
                      //   style={{ color: "#5E4BCE" }}
                      // >{this.state.hidden ? "show" : "hide"}</p>
                      <>
                        {this.state.hidden ? (
                          <i onClick={this.toggleShow} className="fa fa-eye" aria-hidden="true"></i>
                        ) : (
                          <i onClick={this.toggleShow} className="fa fa-eye-slash" aria-hidden="true"></i>
                        )}
                      </>
                    }
                  />
                </div>

                <CustomInput
                  errorText={err.confirmPassword}
                  showError={err.confirmPassword.length > 0}
                  value={data.confirmPassword}
                  onChange={this.handleCreateFormInputChange("confirmPassword", "Confirm Password")}
                  id="login-password"
                  labelText="Confirm Password"
                  placeholder="enter a password again"
                  type={this.state.hide ? "password" : "text"}
                  endIcon={
                    // <p
                    //   onClick={this.secondToggleShow}
                    //   text={this.state.hide ? "show" : "hide"}
                    //   style={{ color: "#5E4BCE" }}
                    // >{this.state.hide ? "show" : "hide"}</p>
                    <>
                      {this.state.hide ? (
                        <i onClick={this.secondToggleShow} className="fa fa-eye" aria-hidden="true"></i>
                      ) : (
                        <i onClick={this.secondToggleShow} className="fa fa-eye-slash" aria-hidden="true"></i>
                      )}
                    </>
                  }
                />
                <p className="mb-2 text-muted"></p>
                {this.state.isError && (
                  <h6 className="text-danger" style={{ marginBottom: "2rem" }}>
                    {this.state.errorText}
                  </h6>
                )}
                {this.state.isSuccess && (
                  <h6 className="text-success" style={{ marginBottom: "2rem" }}>
                    Password reset successfully
                  </h6>
                )}

                <button className="btn btn-primary shadow-2 mb-4 mainColor" style={{ width: "100%" }} disabled={this.state.disableCreate} onClick={this.reset}>
                  {this.state.loading && <CircularProgress size={20} style={{ marginBottom: "-4px" }} color="white" />} Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

export default ResetPassword;

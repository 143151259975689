// import { string } from "prop-types";
import { appHelpers } from "appHelpers";

export const validatorAll = (fields, controller, obj) => {
  // console.log(fields)
  const fieldType = typeof fields;
  const res = {};
  if (typeof fields !== "object") {
    throw new Error(`wrong type passed to validator as first parameter. Expected Array but got ${fieldType}`);
    // return;
  }

  fields.forEach((field) => {
    const { name, value, label } = field;
    const errors = [];
    const rules = getFieldRUles(name) || [];
    if (rules.length < 1) {
      return;
    }
    if (rules.indexOf("required") !== -1) {
      let error = validate("required", value);
      if (error !== null) {
        let errorMsg = getErrorMsg("required", name, label, controller);
        res[name] = errorMsg;
        obj.setState({
          err: { ...obj.state.err, ...res, all: obj.state.err.all.add(name) },
        });
        return res;
      }
    } else {
      // validate other fields like url, without having the required key attribute
      return;
    }

    rules.forEach((rule) => {
      const error = validate(rule, value, field);
      if (error !== null) {
        let errorMsg = getErrorMsg(error, name, label, controller);
        errors.push(errorMsg);
        return;
      }
      return;
    });
    if (errors.length > 0) {
      res[name] = errors;
      obj.setState({
        err: { ...obj.state.err, ...res, all: obj.state.err.all.add(name) },
      });
    }

    obj.setState({ err: { ...obj.state.err, ...res } });
    return;
  });

  return;
};

export const validator = (field, controller, obj) => {
  const res = {};

  const { name, value, label } = field;

  const errors = [];

  const rules = getFieldRUles(name) || [];

  // return early

  if (rules.length < 1) {
    return;
  }
  // handle required condition
  if (rules.indexOf("required") !== -1) {
    let error = validate("required", value);
    if (error !== null) {
      let errorMsg = getErrorMsg("required", name, label, controller);
      res[name] = errorMsg;
      obj.setState({
        err: { ...obj.state.err, ...res, all: obj.state.err.all.add(name) },
      });

      return res;
    }
  }

  // validate rest of the fields' constraints
  rules.forEach((rule) => {
    const error = validate(rule, value, field);
    if (error !== null) {
      let errorMsg = getErrorMsg(error, name, label, controller);
      res[name] = errorMsg;
      errors.push(errorMsg);
    }
    return;
  });

  if (errors.length > 0) {
    res[name] = errors[0];
  } else {
    res[name] = "";
    obj.state.err.all.delete(name);
    obj.setState({ err: { ...obj.state.err, ...res } });
    return;
  }

  obj.setState({
    err: { ...obj.state.err, ...res, all: obj.state.err.all.add(name) },
  });

  return;
};

export const getFieldRUles = (field) => {
  // get Default Rules for common fields
  const rules = validationRules[field] || [];
  return rules.length > 0 ? rules : [];
};

export const validate = (rule, value, fields = null) => {
  // console.log(fields)
  let error = null;
  if (rule === "required") {
    if (value === "" || value === " ") {
      return rule;
    } else if (value === null || value === undefined) {
      return rule;
    } else if (value === false) {
      return rule;
    }
    return error;
  }
  if (rule.includes("min")) {
    let num = parseInt(rule.split(":")[1]);
    // debugger
    if (value.length < num) {
      return rule;
    }
    // else if(value.length <5){
    //     return rule
    // }

    return error;
  }
  if (rule.includes("max")) {
    let num2 = parseInt(rule.split(":")[1]);
    // debugger
    if (value.length > num2) {
      return rule;
    }
    // else if(value.length <5){
    //     return rule
    // }

    return error;
  }
  // if(rule.includes("min")){
  //     if(value.length <5){
  //         return rule
  //     }
  //     return error
  // }
  // if(rule.includes("max")){
  //     if(value.length > 150){
  //         return rule
  //     }
  //     return error
  // }

  if (rule.substr(0, 50) === "max") {
    const len = rule.substr(51);
    return value.length > len ? rule : error;
  }

  if (rule.substr(0, 50) === "min") {
    const len = rule.substr(51);
    return value.length < len ? rule : error;
  }

  if (rule === "string") {
    return /^[-/+]?[+0-9]+$/.test(value) === true ? rule : error;
  }
  if (rule === "allstring") {
    // only letters
    return !/^[a-zA-Z\s]*$/.test(value) === true ? rule : error;
  }
  if (rule === "url") {
    return !appHelpers.isValidUrl(value) === true ? rule : error;
  }
  if (rule === "specialcha") {
    // rul for special character, but also allow space
    var format = /[`!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;

    return format.test(value) === true ? rule : error;
  }
  if (rule === "specialcha_withunderscore") {
    // rul for special character, but also allow space
    let format = /[`!@#$%^&*()+\-=\]{};':"\\|,.<>?~]/;

    return format.test(value) === true ? rule : error;
  }
  if (rule === "nospace") {
    // validate space at beginning and end of string
    format = /^\S*$/;
    return !format.test(value) === true ? rule : error;
  }
  if (rule === "email") {
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let test = re.test(value);
    if (!test) {
      return rule;
    }
    return error;
  }
  if (rule === "password-validate") {
    let re =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    let test = re.test(value);
    if (!test) {
      return rule;
    }
  }
  if (rule === "phoneNumber") {
    var phoneno = /^\d{11}$/;
    if (!value.match(phoneno) && value.trim() !== "") {
      return rule;
    }
    return error;
  }
  if (rule === "match") {
    //console.log("fields in match", fields)
    // this would validate other confirm password fields
    if (fields.name === "confirmPassword") {
      if (value !== fields.password) {
        return rule;
      }
      return error;
    } else {
      // this would validate setup account confirm password field
      if (value !== fields.rpassword) {
        return rule;
      }
      return error;
    }
  }

  if (rule === "number") {
    let reg = /^\d+$/;
    let testr = reg.test(value);
    if (!testr && value.trim() !== "") {
      return rule;
    }
    return error;
  }

  if (rule === "comma") {
    let reg = ",";
    let testr = value.indexOf(reg) !== -1;
    if (!testr && value.trim() !== "") {
      return rule;
    }
    return error;
  }

  return error;
};

// Default Rules for common fields
// any array that has a 'number' means that it is from a dropdown UI :)
export const validationRules = {
  // client-transactions:
  CTserviceCode: ["required"],
  CTconfigurationTag: ["required"],
  // client-transactions sender
  CTfirstName: ["required", "allstring", "specialcha"],
  CTlastName: ["required", "allstring", "specialcha"],
  CTemail: ["required", "email"],
  CTaddress: ["required"],
  CTcity: ["required", "string"],
  CTstate: ["required", "string"],
  CTphoneNumber: ["required", "number"],
  CTcountryCode: ["required"],
  CTcurrencyCode: ["required"],
  CTidentificationType: ["required"],
  CTidentificationNumber: ["required"],
  CTidentificationIssuedDate: ["required"],
  CTidentificationExpiryDate: ["required"],
  CTsourceAmount: [""],
  CTdebitAccountName: ["required", "allstring", "specialcha"],
  CTdebitAccountNumber: ["required"],
  CTdebitAccountBankCode: ["required"],
  CTewalletId: [""],
  CTBfirstName: ["required", "allstring", "specialcha"],
  CTBlastName: ["required", "allstring", "specialcha"],
  CTBemail: ["required", "email"],
  CTBaddress: ["required"],
  CTBcity: ["required", "string"],
  CTBstate: ["required"],
  CTBphoneNumber: ["required", "number"],
  CTBcountryCode: ["required"],
  CTBcurrencyCode: ["required"],
  CTBbeneficiaryAmount: ["required", "number"],

  CTbankName: ["required", "specialcha", "allstring"],
  CTbankCode: ["required"],
  CTaccountNumber: ["required"],
  CTaccountName: ["required", "string", "specialcha"],
  CTbankAddress: ["required"],
  CTbankCity: ["required", "string"],
  CTbankCountry: ["required"],
  CTbankBranch: ["required", "string"],

  CTiban: ["required"],
  CTswiftBICCode: ["required"],
  CTcnapsCode: ["required"],
  CTpayerId: ["required"],
  CTtransactionReference: ["required", "min:16"],
  CTnarration: ["required"],

  // country
  COName: ["required", "string", "specialcha"],
  COISO2: ["required", "string", "max:2", "min:2"],
  COISO3: ["required", "string", "max:3", "min:3"],
  CONumericCode: ["required"],
  // Gateway accout currency pair
  GACgatewayAccountId: ["required"],
  GACcurrencyPairId: ["required"],
  GACisEnabledCreate: [],
  // currency
  CName: ["required", "string", "specialcha"],
  CCurrencyCode: ["required", "string", "specialcha"],
  CNumericCode: ["required", "number"],
  // configuration
  CconfigurationTag: ["required", "min:2", "max:20", "string", "specialcha"],
  CisDefaultCreate: [""],
  CuseDestinationBank: [""],
  CuseDestinationBankEdit: [""],
  Cdescription: ["required"],
  // gateway credential keys
  GCKKey: ["required", "string"],
  GCKServiceId: ["required"],
  // client client service
  CCserviceId: ["required", "number"],
  CCisEnabled: [],
  // gateway response code map
  GRCGatewayResponseMessage: [
    "required",
    "min:4",
    "max:150",
    "specialcha_withunderscore",
  ],
  GRCGatewayResponseCode: [
    "required",
    "min:1",
    "max:150",
    "specialcha_withunderscore",
  ],
  GRCResponseCodeId: ["required", "number"],
  GRCGatewayId: ["required", "number"],
  // destination bank
  DBBankCode: ["required", "min:3", "max:150"],
  DBGatewayId: ["required", "number"],
  DBConfigurationId: ["required", "number"],
  //  client
  ClientName: ["required", "min:2", "max:150", "string", "specialcha"],
  ClientCode: ["required", "min:2", "max:10", "string", "specialcha"],
  ClientuseAML: [""],
  ClientisEnabled: [""],
  ClientcallbackUrl: ["url"],
  ClientFirstName: ["required", "specialcha", "allstring"],
  ClientLastName: ["required", "specialcha", "allstring"],
  ClientEmail: ["required", "email"],
  ClientPhone: ["required", "number"],
  ClientincorporationNumber: ["required"],
  ClientAddress: ["required"],
  ClientCountry: ["required"],
  ClientState: ["required"],
  ClientKycLevel: [""],
  // response code
  ResponseCode: ["required", "specialcha_withunderscore"],
  ResponseMessage: ["required", "string", "specialcha_withunderscore"],
  ServiceId: ["required", "number"],

  //Terminal
  TterminalId: ["required"],
  TconfigurationId: ["required"],
  // curency pair
  SendingCurrencyCode: ["required", "string", "specialcha"],
  SendingCountryCode: ["required", "string", "specialcha"],
  ReceivingCurrencyCode: ["required", "string", "specialcha"],
  ReceivingCountryCode: ["required", "string", "specialcha"],
  // client gateway microService
  CGClient: ["required", "number"],
  CGMicroService: ["required", "number"],
  CGGateway: ["required", "number"],
  CGEnabledCreate: ["required"],
  //  gateway account
  GatewayAccountGatewayId: ["required", "number"],
  GatewayAccountNumber: ["required"],
  GatewayAccountName: ["required", "string", "specialcha"],
  GatewayAccountConfigurationId: ["required", "number"],
  GatewayAccountServiceId: ["required", "number"],
  GatewayAccountPriority: ["required", "number"],
  GatewayAccountIsActive: ["required"],
  GatewayAccountUseAutoSwitch: [""],
  GatewayAccountCredentials: ["required"],

  //  default gateway account
  DefaultGatewayAccountGatewayId: ["required", "number"],
  DefaultGatewayAccountNumber: ["required", "min:10"],
  DefaultGatewayAccountName: ["required", "string", "specialcha"],
  DefaultGatewayAccountServiceId: ["required", "number"],
  DefautGatewayAccountCredentials: [""],
  // payment gatway
  GatewayName: ["required", "min:5", "max:150", "string", "specialcha"],
  GatewayCode: ["required", "min:3", "specialcha"],
  microService: ["required", "number"],
  GatewayEnabledCreate: ["required"],
  GatewayDescription: ["required", "string"],
  GatewayCountry: ["required", "number"],
  // microservice
  MicroServiceName: ["required", "min:5", "max:150", "string", "specialcha"],
  MicroServiceCode: ["required", "min:3", "string", "specialcha"],
  MicroServiceDescription: ["required", "string"],
  MicroServiceEnabled: ["required"],

  //general ledger configuration
  GLCclientId: ["required"],
  GLCconfigurationId: ["required"],
  GLCbankChargesGL: ["required"],
  GLCrevenueGL: ["required"],

  // client user
  ClientUserId: [],
  ClientUserclientId: ["required", "number"],
  ClientUserfirstName: ["required", "string"],
  ClientUserlastName: ["required", "string"],
  ClientUseremail: ["required", "string", "email"],
  ClientUserphoneNumber: ["required", "number"],

  // payment service
  paymentServiceName: ["required", "min:2", "max:150", "string", "specialcha"],
  paymentServiceCode: ["required", "min:2", "string", "specialcha"],
  paymentServiceGroup: ["required", "number"],
  paymentservicedescription: ["required", "string"],
  ServiceEnabledCreate: ["required"],
  UseAMLCreate: [],
  PSchargeValue: ["required", "number"],
  PSisPercentage: [],
  PScountryCode: ["required", "string"],
  PScurrencyCode: ["required", "string"],
  // PSserviceCode: ["required"],
  // PSgatewayCode: ["required"],
  PSserviceId: ["required"],
  PSgatewayId: ["required"],
  // service group
  GroupName: ["required", "min:5", "max:150", "string", "specialcha"],
  GroupDescription: ["required", "max:150", "string"],

  // Bank
  BbankCode: ["required", "min:3", "max:150"],
  BcountryId: ["required"],
  BbankName: ["required", "min:3", "string", "specialcha"],

  // Holding Account
  HAbankId: ["required"],
  HAaccountNumber: ["required", "min:9", "max:12", "number", "specialcha"],
  HAaccountName: ["required", "min:3", "specialcha", "specialcha", "string"],
  HAdescription: ["required", "min:10", "specialcha", "string"],

  // Platform Charge
  PCserviceId: ["required"],
  PCclientId: ["number"],
  PCchargeType: ["required"],
  PCflatValue: ["required", "number"],
  PCpercentageValue: ["required", "number"],
  PCpercentageCap: ["required", "number"],

  //State
  Sname: ["required", "string"],
  Scode: ["required"],
  ScountryId: ["required"],

  // Gateway Charge
  GCserviceId: ["required"],
  GCgatewayId: ["required", "number"],
  GCchargeType: ["required"],
  GCflatValue: ["required", "number"],
  GCpercentageValue: ["required", "number"],
  GCpercentageCap: ["required", "number"],

  // Holding Account
  HAserviceId: ["required"],
  HAclientId: ["required"],
  HAchargeType: ["required", "number"],
  HAflatValue: ["required", "number"],
  HApercentageValue: ["required", "number"],
  HApercentageCap: ["required", "number"],

  // Kyc Service Level
  KLCkycLevel: ["required"],
  KLCserviceId: ["required", "number"],
  KLCmaxAmount: ["required", "min:3", "number"],

  Email: ["required", "email"],
  Password: ["required", "min:8"],

  password: ["required", "min:8"],
  confirmPassword: ["required", "match"],
  cpassword: ["required", "min:8"],

  // change password
  CCurrentPassword: ["required", "min:8"],

  //Setup account
  rpassword: ["required", "min:8", "password-validate"],
  rconfirmPassword: ["required", "match", "password-validate"],

  // user create
  FirstName: ["required", "string", "min:3", "specialcha"],
  LastName: ["required", "string", "min:3", "specialcha"],
  RoleName: ["required", "number"],
  Phone: ["required", "number"],

  PhoneNumber: ["required", "number", "min:11", "max:11"],

  //  role create
  RName: ["required", "string", "min:5", "max:150"],
  RDescription: ["required", "string"],

  // password: ['required', 'min:8'],
  // confirmPassword: ['required', 'match'],
};

export const getErrorMsg = (error, name, label, a, controller = null) => {
  // let a = "a";
  if (
    typeof controller === "string" &&
    ["a", "e", "i", "o", "u"].indexOf(controller[0].toLowerCase()) !== -1
  ) {
    a = "an";
  }

  if (name === "contactName") {
    name = "contact name";
  }
  if (controller === "user") {
    a = "a";
  }

  if (controller === "user" && name === "firstName") {
    name = "first name";
  }
  if (controller === "user" && name === "lastName") {
    name = "last name";
  }
  if (controller === "user" && name === "phone") {
    name = "phonenumber";
  }
  if (error === "required") {
    //return `Please provide ${a} ${controller} ${name}`;
    return `This field is required`;
  }
  if (error === "specialcha") {
    return `Must not contain Special Character(s)`;
  }

  if (error === "specialcha_withunderscore") {
    return `Must not contain Special Character(s)`;
  }

  if (error === "url") {
    return `Must contain a valid url`;
  }

  if (error === "number") {
    return `Please provide a valid number`;
  }

  if (error === "comma") {
    return `Please include a comma`;
  }
  let num = parseInt(error.split(":")[1]);
  if (error.includes("min")) {
    // debugger
    const len = num;
    return `The minimum length for ${label} is ${len}`;
  }

  // if (error.substr(0, 50) === 'max') {
  //     const len = error.substr(51);
  //     return `The maximum length for the ${controller} ${label} is ${len}`;
  // }
  let num2 = parseInt(error.split(":")[1]);
  if (error.includes("max")) {
    const len = num2;
    if (controller !== null) {
      return `The maximum length for ${label} is ${len}`;
    } else {
      return `The maximum length for ${label} is ${len}`;
    }
  }

  // if (error.substr(0, 51) === 'min') {
  //     const len = error.substr(51);
  //     return `The minimum length for the ${controller} ${name} is ${len}`;
  // }

  if (error === "string") {
    return `Enter a correct ${label}`;
  }
  if (error === "allstring") {
    return `Enter a correct ${label}`;
  }

  if (error === "email") {
    return `Please provide a valid email address`;
  }
  if (error === "password-validate") {
    return `minimum (8) eight characters, at least one uppercase letter, one number and one special character is required`;
  }

  if (error === "phoneNumber") {
    return `Please provide a valid phonenumber`;
  }

  if (error === "match") {
    return `confirm password does not match password`;
  }
  if (error === "nospace") {
    return `No spaces allowed at start and end of value`;
  }
};

import actionTypes from '../actions/actionTypes';

const initialState = {
    responseCodes: []
};

const responseCodeReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_RESPONSE_CODE_SUCCESS:
            return {
                ...state,
                responseCodes: action.payload.responseCodes,
            };


        default:
            return state;
    }
};

export default responseCodeReducer;
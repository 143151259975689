import actionTypes from "../actions/actionTypes";

const initialState = {
  allclients: [],
  currentBusiness: {},
  testData:{}
};

const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        allclients: action.payload.allclients,
      };

      case actionTypes.SAVE_CURRENT_CLIENT_SUCCESS:
        return {
          ...state,
          currentBusiness: action.payload.currentBusiness,
        };
        case actionTypes.SAVE_TEST_DATA_SUCCESS:
          return {
            ...state,
            testData: action.payload.testData,
          };

    default:
      return state;
  }
};

export default clientsReducer;

import React from "react";
import { NavLink } from "react-router-dom";

import "../../../assets/scss/style.scss";
import Aux from "../../../components/hoc/_Aux";
import Breadcrumb from "../../../components/Breadcrumb";
import { loginErrors } from "validation";
import CustomInput from "components/Input/CustomInput";
import { appHelpers } from "appHelpers";
import { connect } from "react-redux";
import { saveUserData, saveExpiration } from "logic/actions/actionTypes";
import { CircularProgress } from "@material-ui/core";
import Logo from "../../../assets/images/new_vigipay_light background.svg";
const LOCATIONSTORAGEKEY = "clientNavigationKey";
const PAYMENTGATEWAYSTORAGEKEY = "paymentGatewayNavigationKey";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      err: loginErrors,
      data: {
        Email: "",
        Password: "",
      },
      hidden: true,
      loading: false,
      disableLogin: false,
      isError: false,
    };
    this.toggleShow = this.toggleShow.bind(this);
  }

  componentDidMount() {}

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  login = () => {
    //
    this.setState({ disableLogin: true, loading: true, errorText: "" });
    const { Constants } = this.props;
    this.props.validatorAll(
      [
        { name: "Email", value: this.state.data.Email, label: "Email" },
        { name: "Password", value: this.state.data.Password, label: "Password" },
      ],
      "Login",
      this
    );
    if (this.state.err.all.size > 0) {
      this.setState({ creating: false, disableCreate: false, disableLogin: false, loading: false, errorText: "" });
      return;
    }

    const payload = {
      userName: this.state.data.Email,
      password: this.state.data.Password,
    };
    const LoginService = this.props.Service(null, null);
    LoginService.createItem(payload, Constants.ACCOUNT, Constants.LOGIN)
      .then(res => {
        const { data } = res;
        if (data.requestSuccessful === true) {
          this.setState({ disableLogin: false, loading: false });

          const { responseData } = data;
          const cipherText = appHelpers.cryptographyService().encrypt(responseData.accessToken);
          const permissions = appHelpers.cryptographyService().encryptObject(responseData.permissions);

          const expiresIn = appHelpers.cryptographyService().encryptObject(responseData.expiresIn);
          this.props.dispatch(saveExpiration(responseData.expiresIn));
          this.props.dispatch(saveUserData(responseData));
          localStorage.setItem("SERVICEMANAGER.UI_", JSON.stringify(cipherText));
          localStorage.setItem("SERVICEMANAGER.IU_", JSON.stringify(permissions));
          localStorage.setItem("SERVICEMANAGER.UI--_", JSON.stringify(expiresIn));
          localStorage.setItem(LOCATIONSTORAGEKEY, -1);
          localStorage.setItem(PAYMENTGATEWAYSTORAGEKEY, -1);

          this.props.history.push("/home/clients");
        } else {
          this.setState({ disableLogin: false, loading: false, isError: true, errorText: data.message });
        }
      })
      .catch(err => {
        if (err.response === undefined) {
          this.setState({ disableLogin: false, loading: false, isError: true, errorText: "Check Your Network and try again" });
        }
        if (err.response) {
          this.setState({ disableLogin: false, loading: false, isError: true });
        }
      });
  };
  handleCreateFormInputChange = (input, label) => ({ target: { value } }) => {
    this.setState({ errorText: "", isError: false });
    this.setState(
      prevState => ({
        data: {
          ...prevState.data,
          [input]: value,
        },
      }),
      () => {
        this.props.validator({ name: input, value: value, label: label }, "Login", this);
      }
    );
  };

  enterHandler = () => {
    window.addEventListener("keyup", function (event) {
      // 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        event.preventDefault();
        // Trigger the button element with a click
        let loginTrigger = document.querySelector("#loginButton");
        if (loginTrigger && loginTrigger !== null) {
          loginTrigger.click();
        }
      }
    });
  };

  render() {
    const { err, data } = this.state;
    this.enterHandler();
    return (
      <Aux>
        <Breadcrumb />
        <div className="auth-wrapper">
          <div className="auth-content">
            <div className="card">
              <div className="card-body text-center">
                <img src={Logo} alt="Logo" style={{ width: "190px", height: "80px" }} />
                <h4 className="mb-5">
                  <b>Login</b>
                </h4>
                {/* <h6
                  className="text-muted mt-3"
                  style={{ marginBottom: "2rem" }}
                >
                  Enter login details to proceed
                </h6> */}
                <div className="input-group ">
                  <CustomInput
                    errorText={err.Email}
                    showError={err.Email.length > 0}
                    value={data.Email}
                    onChange={this.handleCreateFormInputChange("Email", "Email")}
                    id="login-email"
                    labelText="Email Address"
                    placeholder="enter an email"
                  />
                </div>

                <CustomInput
                  errorText={err.Password}
                  showError={err.Password.length > 0}
                  value={data.Password}
                  onChange={this.handleCreateFormInputChange("Password", "Password")}
                  id="login-password"
                  labelText="Password"
                  placeholder="enter a password"
                  type={this.state.hidden ? "password" : "text"}
                  endIcon={
                    // <p
                    //   onClick={this.toggleShow}
                    //   text={this.state.hidden ? "show" : "hide"}
                    //   style={{ color: "#5E4BCE" }}
                    // >{this.state.hidden ? "show" : "hide"}</p>
                    <>
                      {this.state.hidden ? (
                        <i onClick={this.toggleShow} className="fa fa-eye" aria-hidden="true"></i>
                      ) : (
                        <i onClick={this.toggleShow} className="fa fa-eye-slash" aria-hidden="true"></i>
                      )}
                    </>
                  }
                />
                {this.state.isError && (
                  <h6 className="text-danger " style={{ marginBottom: "2rem" }}>
                    {this.state.errorText}
                  </h6>
                )}

                <p className="mb-2 text-muted" style={{ textAlign: "end" }}>
                  {" "}
                  <NavLink to="/forgot-password" id="forgot-password-link" style={{ color: "#5E4BCE" }}>
                    Forgot Password ?
                  </NavLink>
                </p>
                <button className="btn btn-primary shadow-2 mb-4 mainColor" style={{ width: "100%" }} onClick={this.login} disabled={this.state.disableLogin} id="loginButton">
                  {this.state.loading && <CircularProgress size={20} style={{ marginBottom: "-4px" }} color="white" />} Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentBusiness: state.clientsReducer.currentBusiness,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch, // ← Add this
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

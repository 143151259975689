export const gatewayAccountCurrencyPairCreateErrors = {
  GACclientId: "",
  GACgatewayAccountId: "",
  GACcurrencyPairId: "",
  GACisEnabledCreate: false,
  all: new Set(),
};

export const configurationCreateErrors = {
  CconfigurationTag: "",
  Cdescription: "",
  all: new Set(),
};
export const terminalCreateErrors = {
  TterminalId: "",
  TconfigurationId: "",
  all: new Set(),
};
export const gatewayAccountCreateErrors = {
  GatewayAccountGatewayId: "",
  GatewayAccountNumber: "",
  GatewayAccountName: "",
  GatewayAccountConfigurationId: "",
  GatewayAccountServiceId: "",
  GatewayAccountPriority: "",
  GatewayAccountUseAutoSwitch: false,
  GatewayAccountCredentials: "",
  GatewayAccountchargeValue: "",
  GatewayAccountisPercentage: false,
  GatewayAccountcountryCode: "",
  GatewayAccountcurrencyCode: "",
  all: new Set(),
};
export const defaultgatewayAccountCreateErrors = {
  DefaultGatewayAccountGatewayId: "",
  DefaultGatewayAccountNumber: "",
  DefaultGatewayAccountName: "",
  DefaultGatewayAccountServiceId: "",
  DefaultGatewayAccountIsActive: false,
  DefaultGatewayAccountCredentials: "",
  all: new Set(),
};

export const generalLedgerConfigurationCreateErrors = {
  GLCclientId: "",
  GLCconfigurationId: "",
  GLCbankChargesGL: "",
  GLCrevenueGL: "",
  GLCisDefault: true,
  all: new Set(),
};
export const paymentGatewayCreateErrors = {
  microService: "",
  GatewayCode: "",
  GatewayName: "",
  GatewayEnabledCreate: false,
  GatewayDescription: "",
  GatewayCountry: "",
  GatewayIsLocal: false,
  GatewayChargeValue: "",
  GatewayIsPercentage: false,
  GatewayCountryCode: "",
  GatewayCurrencyCode: "",
  // GatewayServiceCode: "",
  // GatewayGatewayCode: "",
  GatewayServiceId: "",
  GatewayGatewayId: "",
  all: new Set(),
};
export const destinationBankCreateErrors = {
  DBBankCode: "",
  DBGatewayId: "",
  DBConfigurationId: "",
  DBServiceId: "",
  all: new Set(),
};
export const clientsClientServiceCreateErrors = {
  CCserviceId: "",
  CCisEnabled: false,
  CCchargeValue: "",
  CCisPercentage: false,
  CCcountryCode: "",
  CCcurrencyCode: "",
  all: new Set(),
};
export const clientsCreateErrors = {
  ClientName: "",
  ClientCode: "",
  ClientuseAML: false,
  ClientisEnabled: false,
  ClientvalidationUrl: "",
  ClientcallbackUrl: "",
  ClientFirstName: "",
  ClientLastName: "",
  ClientEmail: "",
  ClientPhone: "",
  ClientincorporationNumber: "",
  ClientAddress: "",
  ClientCountry: "",
  ClientState: "",
  ClientKycLevel: "",
  all: new Set(),
};

export const clientsUserCreateErrors = {
  ClientUserId: "",
  ClientUserclientId: "",
  ClientUserisEnabled: false,
  ClientUserfirstName: "",
  ClientUserlastName: "",
  ClientUseremail: "",
  ClientUserphoneNumber: "",
  all: new Set(),
};

export const clientsUserEditErrors = {
  ClientUserId: "",
  ClientUserclientId: "",
  ClientUserisEnabled: false,
  ClientUserfirstName: "",
  ClientUserlastName: "",
  ClientUseremail: "",
  ClientUserphoneNumber: "",
  all: new Set(),
};

export const currencyPairCreateErrors = {
  SendingCurrencyCode: "",
  SendingCountryCode: "",
  ReceivingCurrencyCode: "",
  ReceivingCountryCode: "",
  all: new Set(),
};

export const gatewayCredentialKeysCreateErrors = {
  GCKKey: "",
  GCKServiceId: "",
  all: new Set(),
};

export const responseCodeCreateErrors = {
  ResponseCode: "",
  ResponseMessage: "",
  ServiceId: "",
  all: new Set(),
};

export const countryCreateErrors = {
  COName: "",
  COISO2: "",
  COISO3: "",
  CONumericCode: "",
  all: new Set(),
};

export const stateCreateErrors = {
  Scode: "",
  Sname: "",
  ScountryId: "",
  all: new Set(),
};

export const currencyCreateErrors = {
  CName: "",
  CCurrencyCode: "",
  CNumericCode: "",
  all: new Set(),
};

export const paymentServiceCreateErrors = {
  paymentServiceName: "",
  paymentServiceCode: "",
  microService: "",
  paymentServiceGroup: "",
  paymentservicedescription: "",
  ServiceEnabledCreate: false,
  CanUseDestinationBankCreate: false,
  UseAMLCreate: false,
  PSchargeValue: "",
  PSisPercentage: false,
  PScountryCode: "",
  PScurrencyCode: "",
  // PSserviceCode: "",
  // PSgatewayCode: "",
  PSserviceId: "",
  PSgatewayId: "",
  all: new Set(),
};
export const clientGatewayMicroserviceCreateErrors = {
  CGClient: "",
  CGMicroService: "",
  CGGateway: "",
  CGEnabledCreate: false,
  all: new Set(),
};

export const gatewayResponseCodeMapCreateErrors = {
  GRCGatewayResponseMessage: "",
  GRCGatewayResponseCode: "",
  GRCResponseCodeId: "",
  GRCGatewayId: "",
  all: new Set(),
};
export const microServiceCreateErrors = {
  MicroServiceName: "",
  MicroServiceCode: "",
  MicroServiceDescription: "",
  MicroServiceEnabled: false,
  all: new Set(),
};
export const userCreateErrors = {
  FirstName: "",
  LastName: "",
  Email: "",
  RoleName: "",
  Phone: "",
  all: new Set(),
};
export const roleCreatErrors = {
  RName: "",
  RDescription: "",
  all: new Set(),
};
export const roleEditErrors = {
  RName: "",
  RDescription: "",
  all: new Set(),
};
export const paymentServiceGroupCreateErrors = {
  GroupName: "",
  GroupDescription: "",
  all: new Set(),
};

export const forgotpasswordErrors = {
  Email: "",
  all: new Set(),
};
export const loginErrors = {
  Email: "",
  Password: "",
  all: new Set(),
};

export const resetPasswordErrors = {
  rpassword: "",
  rconfirmPassword: "",
  all: new Set(),
};
export const newresetPasswordErrors = {
  password: "",
  confirmPassword: "",
  all: new Set(),
};
export const changePasswordErrors = {
  CCurrentPassword: "",
  password: "",
  confirmPassword: "",
  all: new Set(),
};

//

export const gatewayAccountEditErrors = {
  GatewayAccountGatewayId: "",
  GatewayAccountNumber: "",
  GatewayAccountName: "",
  GatewayAccountConfigurationId: "",
  GatewayAccountServiceId: "",
  GatewayAccountPriority: "",
  GatewayAccountchargeValue: "",
  GatewayAccountisPercentage: false,
  GatewayAccountcountryCode: "",
  GatewayAccountcurrencyCode: "",
  all: new Set(),
};

export const defaultgatewayAccountEditErrors = {
  DefaultGatewayAccountGatewayId: "",
  DefaultGatewayAccountNumber: "",
  DefaultGatewayAccountName: "",
  DefaultGatewayAccountServiceId: "",
  DefaultGatewayAccountCredentials: "",
  all: new Set(),
};

export const gatewayCredentialKeysEditErrors = {
  GCKKey: "",
  GCKServiceId: "",
  all: new Set(),
};
export const configurationEditErrors = {
  CconfigurationTag: "",
  Cdescription: "",
  all: new Set(),
};
export const terminalEditErrors = {
  TterminalId: "",
  TconfigurationId: "",
  all: new Set(),
};
export const currencyPairEditErrors = {
  SendingCurrencyCode: "",
  SendingCountryCode: "",
  ReceivingCurrencyCode: "",
  ReceivingCountryCode: "",
  all: new Set(),
};
export const bankEditErrors = {
  BbankCode: "",
  BcountryId: "",
  BbankName: "",
  all: new Set(),
};

export const gatewaychargeEditErrors = {
  GCserviceId: "",
  GCgatewayId: "",
  GCchargeType: "",
  GCflatValue: "",
  GCpercentageValue: "",
  GCpercentageCap: "",
  all: new Set(),
};
export const gatewaychargeCreateErrors = {
  GCserviceId: "",
  GCgatewayId: "",
  GCchargeType: "",
  GCflatValue: "",
  GCpercentageValue: "",
  GCpercentageCap: "",
  all: new Set(),
};

export const platformchargeEditErrors = {
  PCserviceId: "",
  PCclientId: "",
  PCchargeType: "",
  PCflatValue: "",
  PCpercentageValue: "",
  PCpercentageCap: "",
  all: new Set(),
};
export const platformchargeCreateErrors = {
  PCserviceId: "",
  PCclientId: "",
  PCchargeType: "",
  PCflatValue: "",
  PCpercentageValue: "",
  PCpercentageCap: "",
  all: new Set(),
};
export const bankCreateErrors = {
  BbankCode: "",
  BcountryId: "",
  BbankName: "",
  BisEnabled: false,
  all: new Set(),
};

export const generalLedgerConfigurationEditErrors = {
  GLCclientId: "",
  GLCconfigurationId: "",
  GLCbankChargesGL: "",
  GLCrevenueGL: "",
  GLCisDefault: true,
  all: new Set(),
};

export const platformChargeCreateErrors = {
  PCserviceId: "",
  PCclientId: "",
  PCchargeType: "",
  PCflatValue: "",
  PCpercentageValue: "",
  PCpercentageCap: "",
  all: new Set(),
};
export const platformChargeEditErrors = {
  PCserviceId: "",
  PCclientId: "",
  PCchargeType: "",
  PCflatValue: "",
  PCpercentageValue: "",
  PCpercentageCap: "",
  all: new Set(),
};
export const kyclevelconfigCreateErrors = {
  KLCkycLevel: "",
  KLCserviceId: "",
  KLCmaxAmount: "",
  all: new Set(),
};
export const kyclevelconfigEditErrors = {
  KLCkycLevel: "",
  KLCserviceId: "",
  KLCmaxAmount: "",
  all: new Set(),
};
export const holdingAccountCreateErrors = {
  HAbankId: "",
  HAaccountNumber: "",
  HAaccountName: "",
  HAdescription: "",
  HAisDefault: true,
  all: new Set(),
};
export const holdingAccountEditErrors = {
  HAbankId: "",
  HAaccountNumber: "",
  HAaccountName: "",
  HAdescription: "",
  HAisDefault: true,
  all: new Set(),
};
export const gatewayAccountCurrencyPairEditErrors = {
  GACclientId: "",
  GACgatewayAccountId: "",
  GACcurrencyPairId: "",
  all: new Set(),
};
export const countryEditErrors = {
  COName: "",
  COISO2: "",
  COISO3: "",
  CONumericCode: "",
  all: new Set(),
};

export const stateEditErrors = {
  Scode: "",
  Sname: "",
  ScountryId: "",
  all: new Set(),
};
export const currencyEditErrors = {
  CName: "",
  CCurrencyCode: "",
  CNumericCode: "",
  all: new Set(),
};

export const clientsClientServiceEditErrors = {
  CCserviceId: "",
  CCisEnabled: false,
  CCchargeValue: "",
  CCisPercentage: false,
  CCcountryCode: "",
  CCcurrencyCode: "",
  all: new Set(),
};
export const gatewayResponseCodeMapEditErrors = {
  GRCGatewayResponseMessage: "",
  GRCGatewayResponseCode: "",
  GRCResponseCodeId: "",
  GRCGatewayId: "",
  all: new Set(),
};
export const destinationBankEditErrors = {
  DBBankCode: "",
  DBGatewayId: "",
  DBConfigurationId: "",
  DBServiceId: "",
  all: new Set(),
};
export const clientsEditErrors = {
  ClientName: "",
  ClientCode: "",
  ClientuseAMLEdit: false,
  ClientisEnabledEdit: false,
  ClientcallbackUrl: "",
  ClientvalidationUrl: "",
  ClientincorporationNumber: "",
  ClientAddress: "",
  ClientCountry: "",
  ClientState: "",
  ClientKycLevel: "",
  all: new Set(),
};
export const responseCodeEditErrors = {
  ResponseCode: "",
  ResponseMessage: "",
  ServiceId: "",
  all: new Set(),
};
export const userEditErrors = {
  FirstName: "",
  LastName: "",
  Email: "",
  RoleName: "",
  Phone: "",
  all: new Set(),
};
export const userProfileEditErrors = {
  FirstName: "",
  LastName: "",
  Email: "",
  RoleName: "",
  PhoneNumber: "",
  all: new Set(),
};
export const paymentServiceEditErrors = {
  paymentServiceName: "",
  paymentServiceCode: "",
  MicroServiceId: "",
  ServiceGroupId: "",
  paymentservicedescription: "",
  PSchargeValue: "",
  PSisPercentage: false,
  PScountryCode: "",
  PScurrencyCode: "",
  // PSserviceCode: "",
  // PSgatewayCode: "",
  PSserviceId: "",
  PSgatewayId: "",
  all: new Set(),
};

export const paymentServiceGroupEditErrors = {
  GroupName: "",
  GroupDescription: "",
  all: new Set(),
};

export const paymentGatewayEditErrors = {
  microService: "",
  GatewayCode: "",
  GatewayName: "",
  GatewayDescription: "",
  GatewayCountry: "",
  GatewayChargeValue: "",
  GatewayIsPercentage: false,
  GatewayCountryCode: "",
  GatewayCurrencyCode: "",
  // GatewayServiceCode: "",
  // GatewayGatewayCode: "",
  GatewayServiceId: "",
  GatewayGatewayId: "",
  all: new Set(),
};

export const clientGatewayMicroserviceEditErrors = {
  CGClient: "",
  CGMicroService: "",
  CGGateway: "",
  all: new Set(),
};

export const microServiceEditErrors = {
  MicroServiceName: "",
  MicroServiceCode: "",
  MicroServiceDescription: "",
  all: new Set(),
};

export const transactionsCreateErrors = {
  CTserviceCode: "",
  CTconfigurationTag: "",
  CTfirstName: "",
  CTlastName: "",
  CTemail: "",
  CTaddress: "",
  CTcity: "",
  CTstate: "",
  CTphoneNumber: "",
  CTcountryCode: "",
  CTcurrencyCode: "",
  CTidentificationType: "",
  CTidentificationNumber: "",
  CTidentificationIssuedDate: "",
  CTidentificationExpiryDate: "",
  CTsourceAmount: "",
  CTdebitAccountName: "",
  CTdebitAccountNumber: "",
  CTdebitAccountBankCode: "",
  // CTewalletId:"",
  CTBfirstName: "",
  CTBlastName: "",
  CTBemail: "",
  CTBaddress: "",
  CTBcity: "",
  CTBstate: "",
  CTBphoneNumber: "",
  CTBcountryCode: "",
  CTBcurrencyCode: "",
  CTBbeneficiaryAmount: "",

  CTbankName: "",
  CTbankCode: "",
  CTaccountNumber: "",
  CTaccountName: "",
  CTbankAddress: "",
  CTbankCity: "",
  CTbankCountry: "",
  CTbankBranch: "",

  CTiban: "",
  CTswiftBICCode: "",
  CTcnapsCode: "",
  CTpayerId: "",
  CTtransactionReference: "",
  CTnarration: "",
  all: new Set(),
};

export const microserviceFilterErrors = {
  MPaymentGateway: "",
  MPaymentService: "",
  MStatus: "",
};

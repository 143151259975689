import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";

import ChatList from "./ChatList";
import Aux from "../../hoc/_Aux";
import DEMO from "../../../Constants";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CustomAvatar } from "components/Avatar/CustomAvatar";

class NavRight extends Component {
  state = {
    listOpen: false,
  };

  componentDidMount = () => {

  }
  logout = () => {
    console.log("logout props", this.props);
    localStorage.clear();
    this.props.history.push("/login");
  };
  render() {
    const userdata = this.props.userdata && this.props.userdata;
    console.log("userdtaa in navrught", userdata);
    const roleName = userdata.user.roles && userdata.user.roles[0].name;
    return (
      <Aux>
        <ul className="navbar-nav ml-auto">
          {/* <li>
                        <Dropdown alignRight={!this.props.rtlLayout}>
                            <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                                <i className="icon feather icon-bell"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu alignRight className="notification">
                                <div className="noti-head">
                                    <h6 className="d-inline-block m-b-0">Notifications</h6>
                                    <div className="float-right">
                                        <a href={DEMO.BLANK_LINK} className="m-r-10">mark as read</a>
                                        <a href={DEMO.BLANK_LINK}>clear all</a>
                                    </div>
                                </div>
                                <ul className="noti-body">
                                    <li className="n-title">
                                        <p className="m-b-0">NEW</p>
                                    </li>
                                    <li className="notification">
                                        <div className="media">
                                            <img className="img-radius" src={Avatar1} alt="Generic placeholder"/>
                                            <div className="media-body">
                                                <p><strong>John Doe</strong><span className="n-time text-muted"><i
                                                    className="icon feather icon-clock m-r-10"/>30 min</span></p>
                                                <p>New ticket Added</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="n-title">
                                        <p className="m-b-0">EARLIER</p>
                                    </li>
                                    <li className="notification">
                                        <div className="media">
                                            <img className="img-radius" src={Avatar2} alt="Generic placeholder"/>
                                            <div className="media-body">
                                                <p><strong>Joseph William</strong><span className="n-time text-muted"><i
                                                    className="icon feather icon-clock m-r-10"/>30 min</span></p>
                                                <p>Prchace New Theme and make payment</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="notification">
                                        <div className="media">
                                            <img className="img-radius" src={Avatar3} alt="Generic placeholder"/>
                                            <div className="media-body">
                                                <p><strong>Sara Soudein</strong><span className="n-time text-muted"><i
                                                    className="icon feather icon-clock m-r-10"/>30 min</span></p>
                                                <p>currently login</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className="noti-footer">
                                    <a href={DEMO.BLANK_LINK}>show all</a>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li> */}
          {/* <li className={this.props.rtlLayout ? 'm-r-15' : 'm-l-15'}>
                        <a href={DEMO.BLANK_LINK} className="displayChatbox" onClick={() => {this.setState({listOpen: true});}}><i className="icon feather icon-mail"/></a>
                    </li> */}
          <li style={{ display: "flex" }}>
            <div className="d-inline-flex">
              <div >
                <div style={{ position: "inherit", marginTop: "14px" }}>
                  <label>
                    {userdata.user.firstName} {userdata.user.lastName}
                  </label>
                </div>
                <div >
                  <label style={{ fontSize: "10px", margin: "auto" }}>{roleName}</label>
                </div>
              </div>

              <div>
                <Dropdown
                  alignRight={!this.props.rtlLayout}
                  style={{ marginLeft: "30px" }}
                  className="drp-user"
                >
                  <Dropdown.Toggle variant={"link"} id="dropdown-basic">
                    {/* <i className="icon feather icon-settings"/> */}

                    <CustomAvatar
                      fullname={userdata.user.firstName.concat(
                        ` ${userdata.user.lastName}`
                      )}
                      round
                      size="40"
                      color="#4431B4c7"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu alignRight className="profile-notification">
                    <div
                      className="pro-head"
                      style={{ backgroundColor: "#261B64" }}
                    >
                      <span>
                        {userdata.user.lastName} {userdata.user.firstName}
                      </span>
                      <a
                        href={DEMO.BLANK_LINK}
                        className="dud-logout"
                        onClick={this.logout}
                        title="Logout"
                      >
                        <i className="feather icon-log-out" />
                      </a>
                    </div>
                    <ul className="pro-body">
                      {/* <li><a href={DEMO.BLANK_LINK} className="dropdown-item"><i className="feather icon-settings"/> Settings</a></li> */}
                      <li>
                        <Link to="/home/userprofile" className="dropdown-item">
                          <i className="feather icon-user" /> Profile
                      </Link>
                      </li>
                      {/* <li><a href={DEMO.BLANK_LINK} className="dropdown-item"><i className="feather icon-mail"/> My Messages</a></li> */}
                      <li onClick={this.logout}>
                        <a href={DEMO.BLANK_LINK} className="dropdown-item">
                          <i className="feather icon-lock" /> Log Out
                      </a>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </li>
        </ul>
        <ChatList
          listOpen={this.state.listOpen}
          closed={() => {
            this.setState({ listOpen: false });
          }}
        />
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentBusiness: state.clientsReducer.currentBusiness,
    userdata: state.authReducer.userdata,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch, // ← Add this
});

export default connect(mapStateToProps, mapDispatchToProps)(NavRight);

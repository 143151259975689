import actionTypes from '../actions/actionTypes';

const initialState = {
    bank: []
};

const bankReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GET_BANK_SUCCESS:
            return {
                ...state,
                bank: action.payload.bank,
            };


        default:
            return state;
    }
};

export default bankReducer;